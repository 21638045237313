const hostname = window.location.hostname;
let base_url;

if (hostname === 'localhost') {
  // localhost configuration
  base_url = 'http://localhost:5000/happy-sports-e749f/europe-west1/api';
} else {
  // firebase configuration
  base_url = 'https://europe-west1-happy-sports-e749f.cloudfunctions.net/api';
}

module.exports = {
  apiKeys: {
    opencagedata: 'cb33394f8ede49b6aa209d61488d74c2'
  },
  api: {
    base_url: base_url,
    geoloc_api: 'https://api.opencagedata.com',
    end_point: {
      login: "/login",
      registering: "/registering",
      logout: "/logout",
      userGroups: "/user/groups",
      connectedUser: "/user",
      updatePassword: "/user/password",
      groupDetails: "/group/:clubId/:groupId",
      groupUsers: "/group/:clubId/:groupId/users",
      joinClub: "/club/join/:invitation",
      joinGroup: "/club/:clubId/group/:groupId/join",
      searchRoom: "/room/search",
      room: "/room/:roomId",
      rooms: "/rooms",
      newMessage: "/room/:roomId",
      unseenMessage: "/room/unseen/length",
      checkToken: "/check-token",
      leaveGroup: "/user/:clubId/:groupId/leave",
      newPassword: "/new-password",
      configSportsTags: "/config/sports/tags",
      addSport: "/user/sport/add",
      userSportSettings: "/user/sport/:sport/settings",
      userSportRemove: "/user/sport/:sport/delete",
      nearbyUsers: "/user/nearby/partners/:sport",
      getCountryFromLoc: "/geocode/v1/json",
      getWeather: '/weather/:type',
      searchCommunities: '/community/search/:q',
      joinCommunity: '/community/add',
      removeCommunity: '/community/remove'
    }
  },
  links: {
    facebook: 'https://m.me/happysportsapp',
    instagram: 'https://www.instagram.com/happysportsapp/',
    twitter: 'https://twitter.com/happysportsapp',
    linkedin: 'https://www.linkedin.com/company/happysportsapp/',
    discord: 'https://discord.gg/6yF6bAfecq'
  }
};