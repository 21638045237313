import React, { Component } from 'react';
import globalStyle from '../config/style';
import withStyles from '@material-ui/core/styles/withStyles';
import MapPicker from 'react-google-map-picker';

class GoogleMapPicker extends Component {

  constructor (props) {
    super(props);
    this.state = {};
  }

  render = () => {

    try {

      return (
        <MapPicker
          defaultLocation={this.props.location}
          zoom={10}
          mapTypeId={'roadmap'}
          onChangeLocation={ async (lat, lng) => { await this.props.callback(lat, lng) } }
          style={{height:250, marginTop:10}}
          apiKey={'AIzaSyBrIKt_5Y5j4EgK9efDgxWgjgFtET6jmNA'}
        />
      );

    } catch (error) {

      console.log(error);

    }

  }

}

const styles = {};

const App = ( props ) => <GoogleMapPicker {...props} />;

export default withStyles({...globalStyle.style,...styles})( App );
