import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import globalStyle from '../config/style';
import appConfig from '../config/application';
import { Add as AddIcon } from '@mui/icons-material';

class SportFilter extends Component {

  constructor (props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
    const sports = this.exportSports();
    if (sports[0])
      this.props.selectSport( sports[0].id );
  }

  exportSports = () => {

    let sports = [];
    const output = [];
    const availableSports = appConfig.sports;

    if (this.props.groups.length > 0)
      this.props.groups.forEach((group) => {
        sports.push(group.sport);
      });

    if (this.props.sports.length > 0)
      sports = [...sports, ...this.props.sports];

    // array unique
    sports = sports.filter((v, i, a) => a.indexOf(v) === i);

    sports.forEach((sport) => {
      if (output.indexOf(sport) === -1)
        output.push({...availableSports[sport], id: sport});
    });

    return output;

  }

  styleSelectedSport = (sport) => {

    if (this.props.selectedSport === sport)
      return { boxShadow:'0px 0px 10px 2px #333' }
    else
      return { boxShadow:'0px 0px 10px 2px #BBB' }

  }

  renderSportChoice = (k, name, img) => {

    return (
      <div key={k} onClick={() => this.props.selectSport(k)} style={{...this.styleSelectedSport(k), margin:3, position:'relative', height:100, width:100, borderRadius:25, backgroundRepeat:'no-repeat', backgroundPosition:'50% 50%', background:`linear-gradient( rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35) ), url(${img})`, backgroundSize:'cover'}}>
        <div style={{fontFamily:'Poppins-Bold', fontSize:14, color:'#FFF', position:'absolute', bottom:"5px", left:"10px"}}>{name}</div>
      </div>
    );

  }

  render = () => {

    const sports = this.exportSports();
    const sportsRendered = [];

    sports.forEach((sport) => {
      sportsRendered.push(
        this.renderSportChoice(sport.id, sport.text['fr'], sport.img)
      );
    })

    // Add "add sport" option

    if ( sportsRendered.length > 0 )
      sportsRendered.push(
        <div key={'add_sport'} onClick={() => { this.props.setAddSportModal(true, true) }} style={{display:'flex', justifyContent:'center', alignItems:'center', margin:3, marginLeft:10, position:'relative', border:'solid 3px #DDD', height:95, width:95, borderRadius:25}}>
          <AddIcon style={{color:'#CCC', fontSize:72}} />
        </div>
      );

    return (
      <div style={{display:'flex', overflowX:'auto', overflowY:'auto', paddingTop:35}}>
        <div style={{marginBottom:15, display:'flex', flexDirection:'row'}}>
          { sportsRendered }
        </div>
      </div>
    );

  }

}

const styles = {};

export default withStyles({...globalStyle.style,...styles})( SportFilter );
