import React, { Component } from 'react';
import MainLayout from '../layout/main';
import withStyles from '@material-ui/core/styles/withStyles';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import { useNavigate } from 'react-router';
import CircularProgress from '@material-ui/core/CircularProgress';
import globalStyle from '../config/style';
import { setStateAsync } from '../utils/common';
import { fetchGetURL } from '../utils/fetch';
import config from '../config/conf.js';
import translate from '../config/translate.js';
import { useParams } from "react-router-dom";
import GroupSettings from "../component/group-settings";

class GroupsToJoin extends Component {

  constructor (props) {
    super(props);
    this.state = {
      settingsModal: false,
      club: {
        'id': null,
        'name': ''
      },
      groups: [],
      loading: true,
      selectedGroup: {
        id: null
      }
    };
  }

  componentDidMount = async () => {
    document.title = "Rejoindre un club";
    const invitationCode = this.props.params.invitationCode;
    this.fetchData(invitationCode);
  }

  fetchData = async (invitation) => {

    const response = await fetchGetURL(`${config.api.base_url}${config.api.end_point.joinClub}`, {}, {invitation});
    
    if (response.club)
      this.setState({club: {'id': response.club.id, 'name': response.club.name}, groups: response.club.groups, loading: false});
    else
      this.setState({loading: false});

  }

  displayGroupSettings = async (groupId) => {
    await setStateAsync({
      settingsModal: true,
      selectedGroup: {
        ...this.state.selectedGroup,
        id: groupId
      }
    }, this);
  };

  getSelectedGroup = () => {

    const selectedGroup = this.state.selectedGroup.id;
    const groups = this.state.groups;

    let output = groups.find(group => group.id === selectedGroup);

    return {
      ...output,
      invitationCode: this.props.params.invitationCode,
      club: {
        id: this.state.club.id,
        name: this.state.club.name
      }
    };

  }

  setModalStatus = (bool) => {
    this.setState({'settingsModal': bool})
  }

  renderGroups = () => {

    const { classes } = this.props;
    const output = [];

    this.state.groups.forEach((group) => {
      let sportLib = group.sport;
      if (translate[group.sport]) {
        sportLib = translate[group.sport]['fr'];
      }
      output.push(
        <div key={group.id} style={{display:'flex', flexDirection:'column', borderRadius:'15px', boxShadow:'5px 10px 20px 3px #DDD', backgroundColor:'#fff', padding:15, marginTop:20, marginBottom:25}}>
          <div style={{flex:'0 0 100%', width:'100%', textAlign:'center'}}>
            <div style={{fontFamily:'Poppins-Bold', letterSpacing:-1, textTransform:'uppercase', lineHeight:'24px', fontSize:22}}>
              {group.name}
            </div>
          </div>
          <div style={{flex:'0 0 100%', width:'100%', textAlign:'center'}}>
            <div style={{fontFamily:'Poppins-Bold', textTransform:'uppercase', lineHeight:'16px', fontSize:14, color:'#AAA'}}>
              {sportLib}
            </div>
          </div>
          <div style={{flex:'0 0 100%', textAlign:'center', marginTop:10}}>
            <div style={{lineHeight:'17px', color:'#999', fontWeight:'bold', fontSize:15}}>
              {group.description}
            </div>
          </div>
          <div style={{marginTop:20, marginBottom:10}}>
            { group.inGroup ? <Button
              className={classes.joinGroupButton}
              type="submit"
              fullWidth
              variant="contained"
              onClick={() => this.props.navigation(`/group/${this.state.club.id}/${group.id}/`)}
            >
              voir le groupe
            </Button> : <Button
              className={classes.joinGroupButton}
              type="submit"
              fullWidth
              variant="contained"
              onClick={() => this.displayGroupSettings(group.id)}
            >
              rejoindre ce groupe
            </Button>}
          </div>
          <div style={{flex:'0 0 100%', textAlign:'center', marginTop:5}}>
            { group.inGroup ? <div style={{fontSize:13, color:'green', fontWeight:'bold'}}>vous êtes dans ce groupe</div> :
              <div style={{color:'#BBB', fontWeight:'bold', fontSize:13}}>
                {group.userLength} personnes dans ce groupe
              </div> }
          </div>
        </div>
      );
    });

    return output;

  }

  render = () => {

    const { classes } = this.props;

    const body = (
      <Container maxWidth="sm" style={{marginTop:25, marginBottom:65}}>

        { this.state.loading ? <div style={{textAlign:"center", marginTop:50}}><CircularProgress style={{color:"red"}} /></div> : <div>
          <div style={{fontFamily:'Poppins-Bold', textAlign:'center', textTransform:'uppercase', fontSize:24, lineHeight:'24px', marginBottom:25, letterSpacing:-1}}>{this.state.club.name}</div>
          { this.state.groups.length > 0 && <div style={{fontWeight:'bold', fontSize:16, lineHeight:'18px', color:'#777', textAlign:'center', marginBottom:'50px'}}>groupes disponibles</div> }
          { this.state.groups.length > 0 ? this.renderGroups() : <div style={{fontWeight:'bold', fontSize:22, lineHeight:'26px', color:'#777', textAlign:'center', marginTop:50}}>
            le code invitation que vous avez saisi ne semble correspondre à aucun club :(
              <Button
                className={classes.backButton}
                type="submit"
                fullWidth
                variant="contained"
                onClick={() => this.props.navigation(`/subscribes`)}
              >
                retour
              </Button>
            </div> }
        </div> }

        { this.state.selectedGroup.id ? <GroupSettings
          group={this.getSelectedGroup()}
          show={this.state.settingsModal}
          setModalStatus={this.setModalStatus} /> : null }

      </Container>
    );

    return (<MainLayout body={body} params={{invitationCode: this.props.params.invitationCode}} />);

	}

}

const styles = {
  backButton: {
    backgroundColor:globalStyle.colors.primary,
    fontSize:14,
    fontWeight:'bold',
    borderRadius:23,
    height:30,
    color:'#ffffff',
    marginTop: 35
  },
  joinGroupButton: {
    backgroundColor:globalStyle.colors.primary,
    fontSize:14,
    fontWeight:'bold',
    borderRadius:23,
    height:30,
    color:'#ffffff'
  }
};

const App = ( props ) => <GroupsToJoin {...props} navigation={useNavigate()} params={useParams()} />;

export default withStyles({...globalStyle.style,...styles})( App );