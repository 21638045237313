import React, { Component } from 'react';
import MainLayout from '../layout/main';
import withStyles from '@material-ui/core/styles/withStyles';
import Container from '@material-ui/core/Container';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import globalStyle from '../config/style';

class CreateActivity extends Component {

  constructor (props) {
    super(props);
    this.state = {
      showActivityDetails: false
    };
  }

  componentDidMount = async () => {
    document.title = "Happy Sports - Créer une annonce";
  }

  render = () => {

    const { classes } = this.props;

    const body = (
      <Container maxWidth="sm" style={{marginTop:25, marginBottom:65}}>
        
        <InputLabel id="demo-simple-select-label" style={{marginBottom:5}}>Club</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          className={classes.commonSelect}
          value={10}
          fullWidth
          label="Age"
          onChange={()=>{}}
        >
          <MenuItem value={10}>ASSM Saint Médard</MenuItem>
        </Select>
        <InputLabel id="demo-simple-select-label" style={{marginTop:30, marginBottom:5}}>Type de l'activité</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          className={classes.commonSelect}
          value={10}
          fullWidth
          label="Age"
          onChange={()=>{}}
        >
          <MenuItem value={10}>Cours collectif</MenuItem>
        </Select>
        <InputLabel id="demo-simple-select-label" style={{marginTop:30, marginBottom:5}}>Jour</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          className={classes.commonSelect}
          value={10}
          fullWidth
          label="Age"
          onChange={()=>{}}
        >
          <MenuItem value={10}>Dim. 23 janv.</MenuItem>
        </Select>
        <InputLabel id="demo-simple-select-label" style={{marginTop:30, marginBottom:5}}>Heure</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          className={classes.commonSelect}
          value={10}
          fullWidth
          label="Age"
          onChange={()=>{}}
        >
          <MenuItem value={10}>14:00</MenuItem>
        </Select>
        <InputLabel id="demo-simple-select-label" style={{marginTop:30, marginBottom:5}}>Age</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          className={classes.commonSelect}
          value={10}
          fullWidth
          label="Age"
          onChange={()=>{}}
        >
          <MenuItem value={10}>Adulte (18+)</MenuItem>
        </Select>
        <InputLabel id="demo-simple-select-label" style={{marginTop:30, marginBottom:5}}>Niveau</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          className={classes.commonSelect}
          value={10}
          fullWidth
          label="Age"
          onChange={()=>{}}
        >
          <MenuItem value={10}>Intermédiaire</MenuItem>
        </Select>
        <InputLabel id="demo-simple-select-label" style={{marginTop:30, marginBottom:5}}>Durée</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          className={classes.commonSelect}
          value={10}
          fullWidth
          label="Age"
          onChange={()=>{}}
        >
          <MenuItem value={10}>1h30</MenuItem>
        </Select>
        <InputLabel id="demo-simple-select-label" style={{marginTop:30, marginBottom:5}}>Participation</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          className={classes.commonSelect}
          value={10}
          fullWidth
          label="Age"
          onChange={()=>{}}
        >
          <MenuItem value={10}>15€</MenuItem>
        </Select>
        <InputLabel id="demo-simple-select-label" style={{marginTop:30, marginBottom:5}}>Places disponibles</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          className={classes.commonSelect}
          value={10}
          fullWidth
          label="Age"
          onChange={()=>{}}
        >
          <MenuItem value={10}>1</MenuItem>
        </Select>
        <InputLabel id="demo-simple-select-label" style={{marginTop:30, marginBottom:5}}>Certificat médical demandé</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          className={classes.commonSelect}
          value={10}
          fullWidth
          label="Age"
          onChange={()=>{}}
        >
          <MenuItem value={10}>Non</MenuItem>
        </Select>
        <TextField
          variant="outlined"
          className={classes.commonTextField}
          fullWidth
          id="password"
          label="Informations complémentaires"
          name="password"
          type="password"
          multiline
          rows={3}
          style={{marginTop:25, marginBottom:25}}
        />
        <Button
          className={classes.button}
          type="submit"
          fullWidth
          variant="contained"
          onClick={() => {}}
        >
          Créer
        </Button>
      </Container>
    );

    return (<MainLayout body={body} />);

	}

}

const styles = {
  buttonContact: {
    backgroundColor:globalStyle.colors.primary,
    fontSize:14,
    fontWeight:'bold',
    borderRadius:10,
    height:35,
    color:'#ffffff',
    marginTop:10
  },
  button: {
    backgroundColor:globalStyle.colors.primary,
    fontSize:16,
    fontWeight:'bold',
    borderRadius:23,
    height:45,
    color:'#ffffff'
  }
};

export default withStyles({...globalStyle.style,...styles})(CreateActivity);
