import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './screen/login';
import CreateUser from './screen/create-user';
import Home from './screen/home';
import Subscribes from './screen/subscribes';
import MyActivities from './screen/my-activities';
import Parameters from './screen/parameters';
import CreateActivity from './screen/create-activity';
import CreateGroup from './screen/create-group';
import EditGroup from './screen/edit-group';
import Group from './screen/group';
import GroupsToJoin from './screen/groups-to-join';
import Messenger from './screen/messenger';
import Room from './screen/room';
import NotFound from './screen/404';
import Conditions from './screen/conditions';
import NearbyPartners from './screen/nearby-partners';

function App() {
  return (
    <Router>
      <div>
        <Routes>
          <Route exact path="/" element={<Login/>}/>
          <Route exact path="/register" element={<CreateUser/>}/>
          <Route exact path="/home" element={<Home/>}/>
          <Route exact path="/subscribes" element={<Subscribes/>}/>
          <Route exact path="/activities" element={<MyActivities/>}/>
          <Route exact path="/create" element={<CreateActivity/>}/>
          <Route exact path="/parameters" element={<Parameters/>}/>
          <Route exact path="/create-group" element={<CreateGroup/>}/>
          <Route exact path="/edit-group" element={<EditGroup/>}/>
          <Route exact path="/group/:clubId/:groupId/" element={<Group/>}/>
          <Route exact path="/join/:invitationCode" element={<GroupsToJoin/>}/>
          <Route exact path="/messenger" element={<Messenger/>}/>
          <Route exact path="/room/:roomId" element={<Room/>}/>
          <Route exact path="/conditions" element={<Conditions/>}/>
          <Route exact path="/nearby/partners/:sport" element={<NearbyPartners/>}/>
          <Route exact path="*" element={<NotFound/>}/>
        </Routes>
      </div>
    </Router>
  );
}

export default App;
