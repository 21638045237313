import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Container from '@material-ui/core/Container';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { Groups } from '@mui/icons-material';
import globalStyle from '../config/style';
import { ChevronLeft, Settings, Chat } from '@mui/icons-material';
import { useNavigate } from 'react-router';
import Button from '@material-ui/core/Button';
import config from '../config/conf.js';
import { fetchGetURL } from '../utils/fetch';

class MainLayout extends Component {

  constructor (props) {
    super(props);
    this.state = {
      unseenMessage: 0
    };
  }

  componentDidMount = async () => {

    const token = localStorage.getItem('AuthToken');
    let httpResponse;

    if (token) {
      httpResponse = await fetchGetURL(`${config.api.base_url}${config.api.end_point.unseenMessage}`);
      this.setState({unseenMessage: httpResponse.unseenRoomsLength});  
    }

  }

  pressLoginButton = () => {

    if (this.props.params.invitationCode)
      localStorage.setItem('invitationCode', this.props.params.invitationCode);

    this.props.navigation(`/`);

  }

  renderHeader = () => {

    const token = localStorage.getItem('AuthToken');

    if (token) {

      return (
        <AppBar position="fixed">
          <Toolbar style={{minHeight: 50, backgroundColor: '#fff'}}>
            <Container maxWidth="sm" disableGutters={true}> 
              <div style={{display:'flex', justifyContent:'space-between'}}>
                
                { this.props.back ? <div onClick={() => this.props.navigation(this.props.back)} style={{width:32, height:32}}>
                  <ChevronLeft style={{fontSize:32, color:'#000'}} />
                </div> : <div style={{width:32, height:32}}></div> }

                <div onClick={() => this.props.navigation(`/subscribes`)} style={{textAlign:'center', height:32, color:'#000', letterSpacing: '-1px', fontSize:22, fontFamily:"Poppins-Bold"}}>Happy Sports</div>
                
                <div onClick={() => this.props.navigation(`/parameters`)} style={{width:32, height:32}}>
                  <Settings style={{fontSize:32, color:'#000'}} />
                </div>

              </div>
            </Container>
          </Toolbar>
        </AppBar>
      )
    
    } else {

      return (
        <AppBar position="fixed">
          <Toolbar style={{minHeight: 50, backgroundColor: '#fff'}}>
            <Container maxWidth="sm" disableGutters={true}> 
              <div style={{display:'flex', justifyContent:'center'}}>
                <div style={{textAlign:'center', height:32, color:'#000', letterSpacing: '-1px', fontSize:22, fontFamily:"Poppins-Bold"}}>Happy Sports</div>
              </div>
            </Container>
          </Toolbar>
        </AppBar>
      )

    }

  }

  renderFooter = () => {

    const token = localStorage.getItem('AuthToken');
    const { classes } = this.props;

    if (token) {

      return (
        <AppBar position="fixed" style={{top:'auto', bottom:0}}>
          <Toolbar style={{color:'#000', minHeight:50, borderTop:'solid 1px #f1f1f1', backgroundColor:'#ffffff', paddingTop:5, paddingBottom:5}}>
            <Container maxWidth="sm">
              <div style={{display:'flex', alignItems:'center', justifyContent:'space-evenly'}}>
                
                <div onClick={() => this.props.navigation(`/subscribes`)} style={{width:120, textAlign:'center'}}>
                  <div style={{height:32}}>
                    <Groups style={{fontSize:34}} />
                  </div>
                  <div style={{height:22, marginTop:-3, display:'flex', justifyContent:'center', alignItems:'center'}}>
                    <div style={{fontFamily:"Poppins-Bold", letterSpacing:-1, lineHeight:'13px', fontSize:14}}>
                      à proximité
                    </div>
                  </div>
                </div>
                
                <div onClick={() => this.props.navigation(`/messenger`)} style={{width:120, textAlign:'center'}}>
                  <div style={{height:32, position:'relative'}}>
                    { this.state.unseenMessage > 0 && <div style={{position:'absolute', top:'-5px', right:35, backgroundColor:'#ef0707', fontSize:12, lineHeight:'20px', width:20, fontWeight:'bold', borderRadius:'50%', height:20, color:'#FFF'}}>{this.state.unseenMessage}</div> }
                    <Chat style={{fontSize:34}} />
                  </div>
                  <div style={{height:22, marginTop:-3, display:'flex', justifyContent:'center', alignItems:'center'}}>
                    <div style={{fontFamily:"Poppins-Bold", letterSpacing:-1, lineHeight:'13px', fontSize:14}}>
                      messages
                    </div>
                  </div>
                </div>
                
              </div>
            </Container>
          </Toolbar>
        </AppBar>
      );

    } else {

      return (
        <AppBar position="fixed" style={{top:'auto', bottom:0}}>
          <Toolbar style={{color:'#000', minHeight:50, borderTop:'solid 1px #f1f1f1', backgroundColor:'#ffffff', paddingTop:5, paddingBottom:5}}>
            <Container maxWidth="sm">
              <div style={{display:'flex', alignItems:'center', justifyContent:'space-evenly'}}>
                
                <Button
                  className={classes.loginButton}
                  type="submit"
                  fullWidth
                  variant="contained"
                  onClick={this.pressLoginButton}
                >
                  me connecter
                </Button>
                
              </div>
            </Container>
          </Toolbar>
        </AppBar>
      );

    }

  }

  render = () => {

    const { body } = this.props;

    return (
      <Container component="main" maxWidth={false} disableGutters={true}>
        
        { this.renderHeader() }

        <div style={{width:'100%', paddingTop:50, minHeight:'calc(100vh - 125px)', paddingBottom:75, backgroundColor: "#f8f8f8"}}>
          
          {body}

        </div>
        
        
        { !this.props.hideFooter && this.renderFooter() }

      </Container>
    );

  }

}

const styles = {
  spinner: {
    textAlign:'center',
    paddingTop:100
  },
  loginButton: {
    backgroundColor:`${globalStyle.colors.primary}!important`,
    fontSize:16,
    marginTop:10,
    marginBottom:10,
    fontWeight:'bold',
    borderRadius:23,
    height:40,
    color:'#ffffff'
  }
};

const App = ( props ) => <MainLayout {...props} navigation={useNavigate()} />;

export default withStyles({...globalStyle.style,...styles})( App );