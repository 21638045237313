import React, { Component } from 'react';
import LoginLayout from '../layout/login';
import withStyles from '@material-ui/core/styles/withStyles';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import { FavoriteBorder } from '@mui/icons-material';
import { setStateAsync, validateEmail } from '../utils/common';
import globalStyle from '../config/style';
import config from '../config/conf.js';
import { fetchPostURL, fetchGetURL } from '../utils/fetch';
import { useNavigate } from 'react-router';
import logo from '../asset/image/logo500.png';

class Login extends Component {

  constructor (props) {
    super(props);
    this.state = {
      "splashScreen": true,
      "loading": false,
      "club": "Rencontrez de nouvelles personnes en pratiquant vos sports favoris",
      "email": '',
      "password": '',
      'showForgottenPassword': false,
      'newPasswordConfirmation': false,
      "errors": {}
    };
  }

  componentDidMount = async () => {
    document.title = "Happy Sports";
    this.checkToken();
  }

  loginShortcut = (event) => {

    if (event.key === 'Enter')
      this.handleLogin();

  }

  handleChange = (event) => {

    this.setState({
      [event.target.name]: event.target.value
    });

  };

  handleLogin = async () => {

    await setStateAsync({loading: true}, this);

    let check = true;
    const errorMessage = {};
    const fields = ['email', 'password'];

    for (const field of fields) {

      if (this.state[field] === '') {
        errorMessage[field] = 'champ requis';
        check = false;
      }

    };

    if (!check) {

      await setStateAsync({errors: errorMessage, loading: false}, this);
      return false;

    }

    if ( !validateEmail(this.state.email) ) {

      errorMessage.email = 'email invalide';
      await setStateAsync({errors: errorMessage, loading: false}, this);
      return false;

    }

    const httpResponse = await fetchPostURL(`${config.api.base_url}${config.api.end_point.login}`, {email: this.state.email, password: this.state.password});
    
    if (httpResponse.message === 'wrong_credentials') {

      errorMessage.email = 'email ou mot de passe invalide';
      await setStateAsync({errors: errorMessage, loading: false, showForgottenPassword: true}, this);
      return false;

    }

    if ( httpResponse.token && httpResponse.uid ) {

      localStorage.setItem('AuthToken', httpResponse.token);
      localStorage.setItem('UserId', httpResponse.uid);

      const invitationCode = localStorage.getItem('invitationCode');

      if (invitationCode) {
        localStorage.removeItem('invitationCode');
        this.props.navigation(`/join/${invitationCode}`);
      } else {
        this.props.navigation('/subscribes');
      }

    } else {

      errorMessage.email = `une erreur s'est produite`;
      await setStateAsync({errors: errorMessage, loading: false}, this);

    }
    
  }

  checkToken = async () => {

    const token = localStorage.getItem('AuthToken');
    let httpResponse;

    if (token) {
      httpResponse = await fetchGetURL(`${config.api.base_url}${config.api.end_point.checkToken}`, {});
      if (httpResponse === 'token_ok') {
        this.props.navigation('/subscribes');
      } else {
        localStorage.removeItem('AuthToken');
        localStorage.removeItem('UserId');
        this.setState({splashScreen: false});
      }
    } else {
      this.setState({splashScreen: false});
    }

  }

  forgottenPassword = async () => {

    if ( validateEmail(this.state.email) ) {
      this.setState({newPasswordConfirmation: true});
      await fetchPostURL(`${config.api.base_url}${config.api.end_point.newPassword}`, {email: this.state.email});
    }

  }

  newPasswordModal = () => {

    return (
      <Dialog
        open={this.state.newPasswordConfirmation}
        onClose={() => this.setState({newPasswordConfirmation: false})}
      >
        <DialogContent>
          une demande de changement de mot de passe a été envoyé à {this.state.email}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.setState({newPasswordConfirmation: false})}>ok</Button>
        </DialogActions>
      </Dialog>
    );

  }

  renderSplashscreen = () => {

    return (
      <Container maxWidth="sm" style={{marginTop:45, textAlign:'center', marginBottom:65}}>
        <div style={{paddingTop:75}}>
          <div>
            <img style={{width:135}} src={logo} />
          </div>
          <div style={{marginTop:50}}>
            <CircularProgress size={50} style={{color:'#fff'}} />
          </div>
        </div>
      </Container>
    );

  }

  renderLogin = () => {

    const { classes } = this.props;
    const { errors } = this.state;

    const body = (
      <Container maxWidth="sm" style={{marginTop:45, marginBottom:65}}>

        <Typography className={classes.title}>
          Happy Sports
        </Typography>
        <Typography className={classes.description}>{this.state.club}</Typography>
        <TextField
          variant="outlined"
          className={classes.loginTextField}
          fullWidth
          label="email"
          name="email"
          autoComplete="email"
          helperText={errors.email}
          error={errors.email ? true : false}
          onChange={this.handleChange}
          onKeyPress={this.loginShortcut}
        />
        <TextField
          variant="outlined"
          className={classes.loginTextField}
          fullWidth
          label="mot de passe"
          name="password"
          type="password"
          autoComplete="password"
          helperText={errors.password}
          error={errors.password ? true : false}
          onChange={this.handleChange}
          onKeyPress={this.loginShortcut}
        />
        { this.state.showForgottenPassword && <Typography onClick={this.forgottenPassword} style={{ color:"#FFF", fontSize:14, lineHeight:"16px", textAlign:"center", textTransform:"uppercase", marginTop:35 }}>
          mot de passe oublié ?
        </Typography> }
        <Button
          className={classes.loginButton}
          type="submit"
          fullWidth
          variant="contained"
          onClick={this.handleLogin}
        >
          { this.state.loading ? <CircularProgress size={30} style={{color:'#fff'}} /> : "me connecter" }
        </Button>
        <Typography onClick={() => this.props.navigation('/register')} className={classes.newUser}>
          s'inscrire
        </Typography>
        <Typography className={classes.copyright}>
          conçu avec <FavoriteBorder style={{fontSize:12}} /> à Bordeaux
        </Typography>

        {this.newPasswordModal()}

      </Container>
    );

    return body;

	}

  render = () => {

    let body;

    if (this.state.splashScreen) {
      body = this.renderSplashscreen();
    } else {
      body = this.renderLogin();
    }

    return (
      <LoginLayout body={body} />
    );

  }

}

const styles = {
  title: {
    letterSpacing: '-2px',
    fontFamily: "Poppins-Bold",
    color:"#FFF",
    fontWeight: "bolder",
    fontSize: 48,
    textAlign: "center",
    marginBottom: 30
  },
  youAreChoice: {
    color:"#FFF",
    fontWeight: "bolder",
    fontSize: 24,
    lineHeight: "26px",
    textAlign: "center",
    marginTop: 5
  },
  youAre: {
    color:"#FFF",
    fontWeight: "bolder",
    fontSize: 20,
    lineHeight: "22px",
    textAlign: "center"
  },
  description: {
    color:"#FFF",
    fontWeight: "bolder",
    fontSize: 24,
    lineHeight: "30px",
    textAlign: "center",
    textTransform: "uppercase",
    marginTop: 30
  },
  newUser: {
    color:"#FFF",
    fontWeight: "bolder",
    fontSize: 18,
    lineHeight: "22px",
    textAlign: "center",
    textTransform: "uppercase",
    marginTop: 50
  },
  copyright: {
    color:"#FFF",
    fontWeight: "bolder",
    fontSize: 12,
    lineHeight: "16px",
    textAlign: "center",
    textTransform: "uppercase",
    marginTop: 50
  }
}

const App = ( props ) => <Login {...props} navigation={useNavigate()} />;

export default withStyles({...globalStyle.style,...styles})( App );