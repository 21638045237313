const colors = {
  primary: '#EE0A4C',
  secondary: '#F1989B',
  gray: '#AAA'
}

module.exports = {
  colors: colors,
  style: {
    likeALink: {
      cursor: 'pointer'
    },
    loginButton: {
      backgroundColor:`${colors.primary}!important`,
      fontSize:16,
      marginTop:35,
      fontWeight:'bold',
      borderRadius:23,
      height:50,
      color:'#ffffff'
    },
    commonSwitch: {
      "& .MuiSwitch-colorSecondary.Mui-checked": {
        color: '#EEE'
      },
      "& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track": {
        backgroundColor: '#2eca45'
      },
      "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
        opacity:1
      }
    },
    dialogue: {
      "& .MuiDialog-paper": {
        margin:10
      }
    },
    commonAutoComplete: {
      "& .MuiFormLabel-root.Mui-focused":{
        color:'#000',
      },
      "& .MuiInput-underline:after": {
        borderBottomColor: "#000",
      },
    },
    commonSelect: {
      "&.Mui-focused": {
        "&.MuiInput-underline:after": {
          borderBottomColor: "#000"
        },
        "&.MuiSelect-select:focus": {
          backgrounColor: 'white'
        },
      }
    },
    commonTextField: {
      "& .MuiFormLabel-root": {
        color: "#000!important"
      },
      "& .MuiOutlinedInput-root": {
        "& fieldset": { 
          borderColor: "#000",
        },
        "&.Mui-focused fieldset": {
          borderColor: "#000",
        },
        "&.MuiInputLabel-root": {
          color: "#000",
        }
      }
    },
    loginTextField: {
      "marginTop": 35,
      "& .MuiFormLabel-root": {
        color: "white",
        fontWeight: "bolder",
        fontSize: "18px",
      },
      "& .MuiOutlinedInput-root": {
        color: "white",
        fontWeight: "bolder",
        fontSize: "18px",
        "& fieldset": { 
          borderRadius: "20px",
          borderColor: "white",
          borderWidth: "3px"
        },
        "&.Mui-focused fieldset": {
          borderColor: "white",
          borderWidth: "3px"
        },
        "&.MuiInputLabel-root": {
          color: "white",
        }
      },
      "& .MuiOutlinedInput-root:hover": {
        "& fieldset": { 
          borderColor: "white"
        }
      }
    },
    commonButton: {
      "&:hover": {
        backgroundColor:colors.primary,
        color:'#ffffff'
      },
      "&:disabled": {
        backgroundColor:colors.primary,
        color:'#ffffff'
      }
    }
  }
}