import React, { Component } from 'react';
import LoginLayout from '../layout/login';
import withStyles from '@material-ui/core/styles/withStyles';
import Container from '@material-ui/core/Container';
import globalStyle from '../config/style';
import { ChevronLeft } from '@mui/icons-material';
import { useNavigate } from 'react-router';

class Conditions extends Component {

  constructor (props) {
    super(props);
    this.state = {};
  }

  componentDidMount = async () => {
    document.title = "Conditions d'utilisation";
  }

  render = () => {

    const body = (
      <Container maxWidth="sm" style={{marginTop:45, marginBottom:65}}>
        <ChevronLeft onClick={() => this.props.navigation(`/register`)} style={{ color:'white', fontSize:52 }} />
        <div style={{fontFamily:'Poppins-Bold', lineHeight:'24px', fontSize:22, color:'#FFF', marginBottom:5}}>
          Article 1 - Principes
        </div>
        <div style={{fontSize:18, lineHeight:'22px', color:'#FFF', marginBottom:10}}>
          Les présentes conditions générales d'utilisation ont pour objet l'encadrement juridique de l'utilisation de l'application Happy Sports et de ses services.
        </div>
        <div style={{fontSize:18, lineHeight:'22px', color:'#FFF', marginBottom:10}}>
          Les conditions générales d'utilisation doivent être acceptées par tout utilisateur et son accès au site vaut acceptation de ces coonditions.
        </div>

        <div style={{fontFamily:'Poppins-Bold', lineHeight:'24px', marginTop:35, fontSize:22, color:'#FFF', marginBottom:5}}>
          Article 2 - Evolution et durée des CGU
        </div>
        <div style={{fontSize:18, lineHeight:'22px', color:'#FFF', marginBottom:10}}>
          Les présentes conditions génréales d'utilisation sont conclues pour une durée indéterminée. Le contrat produit ses effet à l'égard de l'Utilisateur à compter du début de l'utilisation du service.
        </div>
        <div style={{fontSize:18, lineHeight:'22px', color:'#FFF', marginBottom:10}}>
          L'application Happy Sports se réservice le droit de modifier les clauses de ces conditions générales d'utilisation à tout moment et sans justification.
        </div>

        <div style={{fontFamily:'Poppins-Bold', lineHeight:'24px', marginTop:35, fontSize:22, color:'#FFF', marginBottom:5}}>
          Article 3 - Accès à l'application
        </div>
        <div style={{fontSize:18, lineHeight:'22px', color:'#FFF', marginBottom:10}}>
          L'accès à l'application est exclusivement réservé aux personnes agées de plus de 16 ans.
        </div>
        <div style={{fontSize:18, lineHeight:'22px', color:'#FFF', marginBottom:10}}>
          Le site et ses différents services peuvent être interrompus ou suspendus par l'éditeur, notamment à l'occasion d'une maintenance, sans obligation de préavis ou de justification.
        </div>

        <div style={{fontFamily:'Poppins-Bold', lineHeight:'24px', marginTop:35, fontSize:22, color:'#FFF', marginBottom:5}}>
          Article 4 - Responsabilités
        </div>
        <div style={{fontSize:18, lineHeight:'22px', color:'#FFF', marginBottom:10}}>
          La responsabilité de l'éditeur ne peut pas être engagée en cas de défaillance, panne, difficulté ou interruption de fonctionnement, empêchant l'accès au site ou à une de ses fonctionnalités.
        </div>

        <div style={{fontFamily:'Poppins-Bold', lineHeight:'24px', marginTop:35, fontSize:22, color:'#FFF', marginBottom:5}}>
          Article 5 - Protection des données personnelles
        </div>
        <div style={{fontSize:18, lineHeight:'22px', color:'#FFF', marginBottom:10}}>
          profil: lors de la connexion de l'utilisateur au site web, celui-ci enregistre son prénom, nom, adresse email. Le mot de passe enregistré en base de données est "haché".
        </div>
        <div style={{fontSize:18, lineHeight:'22px', color:'#FFF', marginBottom:10}}>
          messages: lorsque l'application est utilisée pour communiquer avec d'autres utilisateurs, les données concernants les communications de l'utilisateur font l'objet d'une conservation permanente.
        </div>
        <div style={{fontSize:18, lineHeight:'22px', color:'#FFF', marginBottom:10}}>
          cookies: Les cookies ne sont pas utilisés.
        </div>

        <div style={{fontFamily:'Poppins-Bold', lineHeight:'24px', marginTop:35, fontSize:22, color:'#FFF', marginBottom:5}}>
          Article 6 - Utilisation des données personnelles
        </div>
        <div style={{fontSize:18, lineHeight:'22px', color:'#FFF', marginBottom:10}}>
          Les données personnelles collectés auprès des utilisateurs ont pour objectif la mise à disposition des services du site web, leur amélioration et le maintien d'un environnement sécurisé. Plus précisément, les utilisations sont les suivantes :
        </div>
        <div style={{fontSize:18, lineHeight:'22px', color:'#FFF', marginBottom:10}}>
          - accès et utilisation de l'application par l'utilisateur.
        </div>
        <div style={{fontSize:18, lineHeight:'22px', color:'#FFF', marginBottom:10}}>
          - Les utilisateurs n'auront pas accès aux emails des autres utilisateurs.
        </div>
        <div style={{fontSize:18, lineHeight:'22px', color:'#FFF', marginBottom:10}}>
          - Les emails ne sauront pas divulgés ou revendus à des fins publicitaires à des acteurs tiers.
        </div>
        <div style={{fontSize:18, lineHeight:'22px', color:'#FFF', marginBottom:10}}>
          - Les emails permettront à Happy Sports de contacter et d'informer les utilisateurs (oubli de mot de passe, notification ou recommandation).
        </div>

        <div style={{fontFamily:'Poppins-Bold', lineHeight:'24px', marginTop:35, fontSize:22, color:'#FFF', marginBottom:5}}>
          Article 7 - Sécurité et confidentialité
        </div>
        <div style={{fontSize:18, lineHeight:'22px', color:'#FFF', marginBottom:10}}>
          L'application met en oeuvre des mesures organisationnelles, techniques, logicielles et physiques en matière de sécurité du numérique pour protéger les données personnelles contre les altérations, destructions et accès non autorisés. Toute, il est à signaler qu'Internet n'est pas un environnement complètement sécurisé et l'application ne peut pas garantir la sécurité de la transmission ou du stockage des informations.
        </div>

        <div style={{fontFamily:'Poppins-Bold', lineHeight:'24px', marginTop:35, fontSize:22, color:'#FFF', marginBottom:5}}>
          Article 8 - Loi applicable
        </div>
        <div style={{fontSize:18, lineHeight:'22px', color:'#FFF', marginBottom:10}}>
          Les présentes conditions générales d'utilisation sont soumises à l'application du droit français.
        </div>
        <div style={{fontSize:18, lineHeight:'22px', color:'#FFF', marginBottom:10}}>
          Si les parties n'arrivent pas à résoudre un litige à l'amiable, le litige sera soumis à la compétence des tribunaux français.
        </div>

        <div style={{fontSize:18, lineHeight:'22px', color:'#FFF', marginBottom:10}}>
          Le service Happy Sports est hebergé par Google Cloud, société SARL unipersonnelle au capital de 1000000€ dont le siège social est situé à 8 rue de Londres 75009 Paris France, immatriculée au registre du commerce et des sociétés de Paris, sous le numéro 881 621 583.
        </div>

        <div style={{fontSize:18, lineHeight:'22px', color:'#FFF', marginBottom:10}}>
          Le service Happy Sports est géré par Paul Danilo AE - SIRET 51904292300028 
        </div>

      </Container>
    );

    return (<LoginLayout body={body} />);

	}

}

const styles = {
  registerButton: {
    backgroundColor:`${globalStyle.colors.primary}!important`,
    fontSize:16,
    marginTop:35,
    fontWeight:'bold',
    borderRadius:23,
    height:50,
    color:'#ffffff'
  }
}

const App = ( props ) => <Conditions {...props} navigation={useNavigate()} />;

export default withStyles({...globalStyle.style,...styles})( App );