import React, { Component } from 'react';
import MainLayout from '../layout/main';
import withStyles from '@material-ui/core/styles/withStyles';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useNavigate } from 'react-router';
import { Facebook, Instagram, Twitter, LinkedIn } from '@mui/icons-material';
import { ReactComponent as Discord } from '../asset/image/discord.svg';
import globalStyle from '../config/style';
import { fetchPostURL, fetchGetURL } from '../utils/fetch';
import config from '../config/conf.js';
import { setStateAsync, validateEmail } from '../utils/common';
import { fetchGetURLWithCache } from '../utils/cache';
import { Delete as DeleteIcon, Settings as SettingsIcon } from '@mui/icons-material';
import appConfig from '../config/application.js';
import AddSport from "../component/add-sport";
import AddInterest from "../component/interest-points";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { clearCache } from '../utils/cache';

class Parameters extends Component {

  constructor (props) {
    super(props);
    this.state = {
      user: {
        'firstname': '',
        'lastname': '',
        'email': ''
      },
      newPassword: '',
      newPasswordVerif: '',
      loading: true,
      loadingUserInformations: false,
      loadingNewPassword: false,
      errors: {},
      sports: [],
      communities: [],
      showAddSportModal: false,
      showAddInterestModal: false,
      sportToUpdate: null,
      removeSportModal: {
        show: false,
        sport: null
      }
    };
  }

  componentDidMount = async () => {
    document.title = "Paramètres";
    await this.loadUserData();
  }

  logout = async () => {

    const httpResponse = await fetchPostURL(`${config.api.base_url}${config.api.end_point.logout}`, {});

    localStorage.removeItem('AuthToken');
    localStorage.removeItem('UserId');
    await clearCache('userGroups');
    await clearCache('configSportsTags');

    this.props.navigation('/');

  }

  socialNetwork = () => {

    return (
      <div>
        <div style={{textAlign:'center', fontSize:14, color:'#666'}}>contacter Happy Sports</div>
        <div style={{display:'flex', justifyContent:'center', marginTop:5}}>
          <Link target={"_blank"} href={config.links.discord}> <Discord style={{height:27, margin:7, marginTop:11, marginRight:-8}} /> </Link>
          <Link target={"_blank"} style={{color:'#0e76a8'}} href={config.links.linkedin}> <LinkedIn style={{fontSize:36, margin:7}} /> </Link>
          <Link target={"_blank"} style={{color:'#3b5998'}} href={config.links.facebook}> <Facebook style={{fontSize:36, margin:7}} /> </Link>
          <Link target={"_blank"} style={{color:'#E1306C'}} href={config.links.instagram}> <Instagram style={{fontSize:36, margin:7}} /> </Link>
          <Link target={"_blank"} style={{color:'#1DA1F2'}} href={config.links.twitter}> <Twitter style={{fontSize:36, margin:7}} /> </Link>
        </div>
      </div>
    );

  }

  loadUserData = async () => {

    const httpResponse = await fetchGetURL(`${config.api.base_url}${config.api.end_point.connectedUser}`, {});
    const userGroupsResponse = await fetchGetURLWithCache('userGroups', `${config.api.base_url}${config.api.end_point.userGroups}`, {}, {}, true);
    
    await setStateAsync({sports: userGroupsResponse.sports}, this);

    if (httpResponse.email)
      await setStateAsync({user: httpResponse}, this);

    if (httpResponse.communities)
      await setStateAsync({communities: httpResponse.communities}, this);

    await setStateAsync({loading: false}, this);

  }

  handleChange = (event) => {
    this.setState({
      user: {
        ...this.state.user,
        [event.target.id]: event.target.value
      }
    });
  };

  handleChangePW = (event) => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  updateUserData = async () => {

    await setStateAsync({loadingUserInformations: true}, this);

    const errorMessage = {};
    let check = true;

    const fields = ['firstname', 'lastname', 'email'];

    for (const field of fields) {

      if (this.state.user[field] === '') {
        errorMessage[field] = 'champ requis';
        check = false;
      }

    };

    if ( !validateEmail(this.state.user.email) ) {
      errorMessage.email = 'email incorrect';
      check = false;
    }

    if (!check) {

      await setStateAsync({errors: errorMessage}, this);
      return false;

    }

    await setStateAsync({errors: {}}, this);

    const payload = {
      'firstname': this.state.user.firstname,
      'lastname': this.state.user.lastname,
      'email': this.state.user.email
    };

    await fetchPostURL(`${config.api.base_url}${config.api.end_point.connectedUser}`, payload);

    await setStateAsync({loadingUserInformations: false}, this);
  
  }

  updatePassword = async () => {

    await setStateAsync({loadingNewPassword: true}, this);

    const errorMessage = {};

    if (this.state.newPassword === '') {
      errorMessage.password = 'vous devez saisir un mot de passe';
      await setStateAsync({errors: errorMessage, loadingNewPassword: false}, this);
      return false;
    }

    if (this.state.newPassword !== this.state.newPasswordVerif) {
      errorMessage.password = 'les mots de passe doivent correspondre';
      await setStateAsync({errors: errorMessage, loadingNewPassword: false}, this);
      return false;
    }

    await setStateAsync({errors: {}}, this);
    await fetchPostURL(`${config.api.base_url}${config.api.end_point.updatePassword}`, {password: this.state.newPassword});

    await setStateAsync({loadingNewPassword: false}, this);

  }

  toggleAddSportModal = (bool, sport) => {

    this.setState({
      showAddSportModal: bool,
      sportToUpdate: sport
    });

  }

  toggleInterestModal = (bool) => {

    this.setState({
      showAddInterestModal: bool
    });

  }

  renderSports = () => {

    const sports = [];
    const availableSports = appConfig.sports;
    const { classes } = this.props;

    this.state.sports.forEach((sport) => {
      const thisSport = availableSports[sport];
      sports.push(
        <div key={sport}>
          <div style={{display:'flex', marginBottom:10, justifyContent: 'space-between', padding:5, borderRadius:10, border:'solid 1px #CCC', backgroundColor:'#FFF'}}>
            <div style={{display:'flex'}}>
              <div style={{margin:3, position:'relative', height:60, width:60, borderRadius:10, backgroundRepeat:'no-repeat', backgroundPosition:'50% 50%', background:`linear-gradient( rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35) ), url(${thisSport.img})`, backgroundSize:'cover'}}></div>
              <div style={{display:'flex', alignItems:'center'}}>
                <div style={{fontFamily:'Poppins-Bold', fontSize:18, lineHeight:'20px', marginLeft:7}}>{thisSport.text['fr']}</div>
              </div>
            </div>
            <div style={{display:'flex'}}>
              <div onClick={() => this.toggleAddSportModal(true, sport)} style={{display:'flex', paddingLeft:5, paddingRight:5, alignItems:'center'}}>
                <SettingsIcon style={{color:'#EE0A4C', fontSize:28}} />
              </div>
              <div style={{display:'flex', paddingLeft:5, paddingRight:10, alignItems:'center'}}>
                <DeleteIcon onClick={() => this.setState({removeSportModal: {show: true, sport}})} style={{color:'#333', fontSize:28}} />
              </div>
            </div>
          </div>
        </div>
      );
    });

    return (
      <div>
        {sports}
        <Button
          className={classes.button}
          type="submit"
          fullWidth
          variant="contained"
          onClick={() => this.toggleAddSportModal(true, false)}
        >
            ajouter un sport
        </Button>
      </div>
    );

  }

  renderCommunities = () => {

    const output = [];
    const communitiesTypes = appConfig.communitiesTypes;

    this.state.communities.forEach((community) => {

      const type = communitiesTypes.filter(communityType => communityType.type === community.type);
      let typeTxt = '';

      if (type[0] && type[0].name && type[0].name['fr'])
        typeTxt = type[0].name['fr'];
      
      output.push(
        <div key={community.ref}>
          <div style={{display:'flex', marginBottom:10, justifyContent: 'space-between', padding:'20px 10px 20px 10px', borderRadius:10, border:'solid 1px #CCC', backgroundColor:'#FFF'}}>
            <div style={{display:'flex', flexDirection:'column'}}>
              <div style={{fontFamily:'Poppins-Bold', lineHeight:'18px', fontSize:18}}>{community.name}</div>
              <div style={{color:'#666', letterSpacing:-1, fontSize:16, lineHeight:'16px', marginTop:5}}>{ typeTxt }</div>
            </div>
            <div style={{display:'flex', paddingLeft:5, paddingRight:10, alignItems:'center'}}>
              <DeleteIcon onClick={() => this.removeCommunity(community.ref)} style={{color:'#333', fontSize:28}} />
            </div>
          </div>
        </div>
      );
    });

    return output;

  }

  handleDeleteSport = async () => {

    const sport = this.state.removeSportModal.sport;
    await fetchPostURL(`${config.api.base_url}${config.api.end_point.userSportRemove}`, {}, {sport});
    await clearCache('userGroups');
    window.location.reload();

  }

  deleteSportRenderModal = () => {

    return (
      <Dialog
        open={this.state.removeSportModal.show}
        onClose={() => this.setState({removeSportModal: {show: false}})}
      >
        <DialogTitle style={{fontFamily:'Poppins-Bold', letterSpacing:-1}}>
          retirer ce sport ?
        </DialogTitle>
        <DialogActions>
          <Button onClick={() => this.setState({removeSportModal: {show: false}})}>annuler</Button>
          <Button onClick={this.handleDeleteSport} autoFocus>
            oui
          </Button>
        </DialogActions>
      </Dialog>
    );

  }

  removeCommunity = async (communityRef) => {

    const httpResponse = await fetchPostURL(`${config.api.base_url}${config.api.end_point.removeCommunity}`, {ref: communityRef});
    await this.loadUserData();

  }

  renderInterestPoints = () => {

    const { classes } = this.props;
    const totalCommunities = 5;
    const joinedCommunities = this.state.communities.length;

    return (
      <div>
        { this.renderCommunities() }
        { (totalCommunities-joinedCommunities) > 0 && <Button
          className={classes.button}
          type="submit"
          fullWidth
          variant="contained"
          onClick={() => this.toggleInterestModal(true)}
        >
          rejoindre une communauté
        </Button> }
        <div style={{fontFamily:'Poppins-Bold', marginTop:20, textAlign:'center', color:'#333', letterSpacing:-0.5, textTransform:'uppercase', fontSize:14, lineHeight:'14px'}}>{`communautés restantes: ${(totalCommunities-joinedCommunities)}/${totalCommunities}`}</div>
      </div>
    );

  }

  render = () => {

    const { classes } = this.props;
    const { errors } = this.state;

    const body = (
      this.state.loading ?
        <div style={{textAlign:"center", marginTop:50}}><CircularProgress style={{color:"red"}} /></div> :
        <Container maxWidth="sm" style={{marginTop:25, marginBottom:65}}>

          { this.state.sports.length > 0 && <div style={{fontFamily:'Poppins-Bold', textTransform:'uppercase', letterSpacing:-1, fontSize:22}}>Mes sports</div> }
          <div style={{fontSize:15, lineHeight:'17px', marginTop:5, marginBottom:10, color:'#666'}}>Configurez vos sports favoris pour être visible auprès des autres sportifs à proximité.</div>
          { this.renderSports() }

          <div style={{fontFamily:'Poppins-Bold', fontSize:22, textTransform:'uppercase', letterSpacing:-1, marginTop:50}}>Mes communautés</div>
          <div style={{fontSize:15, lineHeight:'17px', marginTop:5, marginBottom:20, color:'#666'}}>Quand vous recherchez des partenaires à proximité, vos communautés en commun seront mises en avant. Rejoignez vos clubs, universités ou même fanclubs.</div>
          { this.renderInterestPoints() }

          <div style={{fontFamily:'Poppins-Bold', fontSize:22, textTransform:'uppercase', letterSpacing:-1, marginTop:50}}>Mes informations</div>
          <TextField
            variant="outlined"
            className={classes.commonTextField}
            style={styles.textField}
            fullWidth
            id="firstname"
            label="prénom"
            autoComplete="firstname"
            value={this.state.user.firstname}
            onChange={this.handleChange}
            helperText={errors.firstname}
            error={errors.firstname ? true : false}
          />
          <TextField
            variant="outlined"
            className={classes.commonTextField}
            style={styles.textField}
            fullWidth
            id="lastname"
            label="nom"
            autoComplete="lastname"
            value={this.state.user.lastname}
            onChange={this.handleChange}
            helperText={errors.lastname}
            error={errors.lastname ? true : false}
          />
          <TextField
            variant="outlined"
            className={classes.commonTextField}
            style={styles.textField}
            fullWidth
            id="email"
            label="email"
            autoComplete="email"
            value={this.state.user.email}
            onChange={this.handleChange}
            helperText={errors.email}
            error={errors.email ? true : false}
          />
          <Button
            className={classes.button}
            type="submit"
            fullWidth
            variant="contained"
            onClick={this.updateUserData}
          >
              {this.state.loadingUserInformations ? <CircularProgress size={30} style={{color:'#fff'}} /> : "enregistrer" }
          </Button>
  
          <div style={{marginTop:50}}>
            <div style={{fontFamily:'Poppins-Bold', fontSize:22, textTransform:'uppercase', letterSpacing:-1, marginTop:50}}>Mot de passe</div>
            <TextField
              variant="outlined"
              className={classes.commonTextField}
              style={styles.textField}
              fullWidth
              id="newPassword"
              label="nouveau mot de passe"
              type="password"
              onChange={this.handleChangePW}
              helperText={errors.password}
              error={errors.password ? true : false}
            />
            <TextField
              variant="outlined"
              className={classes.commonTextField}
              style={styles.textField}
              fullWidth
              id="newPasswordVerif"
              label="nouveau mot de passe (confirmation)"
              type="password"
              onChange={this.handleChangePW}
              error={errors.password ? true : false}
            />
            <Button
              className={classes.button}
              type="submit"
              fullWidth
              variant="contained"
              onClick={this.updatePassword}
            >
              {this.state.loadingNewPassword ? <CircularProgress size={30} style={{color:'#fff'}} /> : "enregistrer" }
            </Button>
          </div>
  
          <div style={{marginTop:50}}>
            <Button
              className={classes.button}
              type="submit"
              fullWidth
              variant="contained"
              onClick={this.logout}
            >
              Me déconnecter
            </Button>
          </div>
  
          <div style={{marginTop:50}}>
            {this.socialNetwork()}
          </div>

          { this.deleteSportRenderModal() }

          { this.state.showAddSportModal && <AddSport
            sport={this.state.sportToUpdate}
            showModal={this.state.showAddSportModal}
            setModalStatus={this.toggleAddSportModal} /> }

            <AddInterest
              showModal={this.state.showAddInterestModal}
              setModalStatus={this.toggleInterestModal}
              callback={this.loadUserData}
            />
  
        </Container>
      
    );

    return (<MainLayout body={body} />);

	}

}

const styles = {
  button: {
    backgroundColor:`${globalStyle.colors.primary}!important`,
    fontSize:14,
    fontWeight:'bold',
    borderRadius:23,
    height:35,
    color:'#ffffff',
    marginTop:20
  },
  textField: {
    marginTop:20,
    backgroundColor:'#FFF'
  }
};

const App = ( props ) => <Parameters {...props} navigation={useNavigate()} />

export default withStyles({...globalStyle.style,...styles})( App );