import { fetchGetURL } from './fetch';

export const clearCache = (cacheId) => {
  localStorage.removeItem(cacheId);
}

export const fetchGetURLWithCache = async (cacheId, url, params, path, redirectToLogin) => {

  const ts = Math.floor( Date.now() / 1000 );
  let cache = localStorage.getItem(cacheId);
  
  if (cache) {

    cache = JSON.parse(cache);
    const elapsedTime = ts - cache.ts;

    if (elapsedTime < 3600)
      return cache.data;

  }

  const data = await fetchGetURL(url, params, path, redirectToLogin);

  localStorage.setItem( cacheId, JSON.stringify({ts, data}) );

  return data;

};

