import React, { Component } from 'react';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Close } from '@mui/icons-material';
import globalStyle from '../config/style';
import withStyles from '@material-ui/core/styles/withStyles';
import { useNavigate } from 'react-router';
import appConfig from '../config/application.js';
import { AddCircle as AddIcon, Facebook, Instagram, Twitter, LinkedIn } from '@mui/icons-material';
import Link from '@material-ui/core/Link';
import { setStateAsync, sortDictionnaryByKey } from '../utils/common';
import { fetchGetURLWithCache } from '../utils/cache';
import config from '../config/conf.js';
import { fetchPostURL, fetchGetURL } from '../utils/fetch';
import { clearCache } from '../utils/cache';
import GoogleMapPicker from './google-map-picker';
import TextField from '@material-ui/core/TextField';

class AddInterest extends Component {

  constructor (props) {
    super(props);
    this.state = {
      search: null,
      searchLoading: false,
      addingLoading: '',
      communities: []
    };
  }

  componentDidMount = () => {}

  debounce = (func) => {
    let timer;
    return (...args) => {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 1000);
    };
  };

  handleChange = async (value) => {

    await setStateAsync({searchLoading: true}, this);

    if (value !== '') {
      const communities = await fetchGetURL(`${config.api.base_url}${config.api.end_point.searchCommunities}`, {}, {q: value});
      await setStateAsync({communities}, this);
    }

    await setStateAsync({searchLoading: false}, this);
    
  };

  optimizedFn = this.debounce(this.handleChange);

  socialNetwork = () => {

    return (
      <div>
        <div style={{display:'flex', justifyContent:'center'}}>
          <Link target={"_blank"} style={{color:'#CCC'}} href={config.links.linkedin}> <LinkedIn style={{fontSize:32, margin:5}} /> </Link>
          <Link target={"_blank"} style={{color:'#CCC'}} href={config.links.facebook}> <Facebook style={{fontSize:32, margin:5}} /> </Link>
          <Link target={"_blank"} style={{color:'#CCC'}} href={config.links.instagram}> <Instagram style={{fontSize:32, margin:5}} /> </Link>
          <Link target={"_blank"} style={{color:'#CCC'}} href={config.links.twitter}> <Twitter style={{fontSize:32, margin:5}} /> </Link>
        </div>
      </div>
    );

  }

  formatCommunityType = (type) => {

    const communitiesTypes = appConfig.communitiesTypes;

    const configTypes = communitiesTypes.filter(communityType => communityType.type === type);
    let output = '';

    if (configTypes[0] && configTypes[0].name && configTypes[0].name['fr'])
      output = configTypes[0].name['fr'];

    return output;

  }

  closePopover = async () => {

    await setStateAsync({communities: []}, this);
    await this.props.callback();
    this.props.setModalStatus(false);

  }

  joinCommunity = async (communityRef) => {

    await setStateAsync({addingLoading: communityRef}, this);

    // CALL THE API TO JOIN THIS COMMUNITY
    await fetchPostURL(`${config.api.base_url}${config.api.end_point.joinCommunity}`, {ref: communityRef});

    // REMOVE THE COMMUNITY FROM THE SUGGEST LIST
    const communities = this.state.communities.filter(community => community.ref !== communityRef);
    await setStateAsync({communities}, this);

    await setStateAsync({addingLoading: ''}, this);

  }

  renderCommunities = () => {

    const output = [];

    this.state.communities.forEach((community, k) => {
      output.push(
        <div ref={k} style={{display:'flex', justifyContent:'space-between', borderBottom:'solid 1px #DDD', padding:7, marginBottom:15}}>
          <div>
            <div style={{fontFamily:'Poppins-Bold', lineHeight:'16px', fontSize:16}}>{community.name['fr']}</div>
            <div style={{color:'#666', letterSpacing:-1, fontSize:14, lineHeight:'14px', marginTop:5}}>{ this.formatCommunityType(community.type) }</div>
          </div>
          <div>
            { this.state.addingLoading === community.ref ? <CircularProgress style={{color:'#ee0a4c'}} size={26} /> : <AddIcon onClick={() => this.joinCommunity(community.ref)} style={{color:'#ee0a4c'}} /> }
          </div>
        </div>
      );
    });

    return output;

  }

  render = () => {

    const { classes } = this.props;

    return (
      <Dialog
        open={this.props.showModal}
        className={classes.dialogue}
        onClose={ () => this.closePopover() }
      >
        <DialogContent>
          <Container maxWidth="sm" style={{padding:0, paddingTop:15}}>

            <div style={{position:'relative'}}>
              <TextField
                variant="outlined"
                className={classes.commonTextField}
                style={styles.textField}
                fullWidth
                id="search"
                label="rechercher une communauté"
                value={this.state.search}
                onChange={(e) => this.optimizedFn(e.target.value)}
                helperText={null}
                error={false}
              />
              <div style={{position:'absolute', top:15, right:10}}>
                { this.state.searchLoading && <CircularProgress color={'#000'} size={26} /> }
              </div>
            </div>

            <div style={{fontFamily:'Poppins-Bold', marginTop:10, textAlign:'center', color:'#999', textTransform:'uppercase', fontSize:14, lineHeight:'14px'}}>Astuce: entrez un code postal pour rechercher un club</div>

            <div style={{marginTop:35}}>

              {this.renderCommunities()}

              <div style={{fontFamily:'Poppins-Bold', marginTop:50, textAlign:'center', color:'#999', letterSpacing:-1, textTransform:'uppercase', fontSize:12, lineHeight:'12px'}}>pour proposer de nouvelles communautés n'hésitez pas à nous contacter:</div>

              <div style={{marginTop:10}}>
                {this.socialNetwork()}
              </div>

            </div>

            <div style={{color:'#FFF'}}>------------------------------------------</div>          
          </Container>
        </DialogContent>
      </Dialog>
    );

  }

}

const styles = {
  geoButton: {
    backgroundColor:`#333!important`,
    fontSize:14,
    fontWeight:'bold',
    borderRadius:23,
    height:30,
    color:'#FFF'
  },
  commonButton: {
    backgroundColor:`${globalStyle.colors.primary}!important`,
    fontSize:14,
    fontWeight:'bold',
    borderRadius:23,
    height:30,
    color:'#ffffff'
  },
  loginButton: {
    backgroundColor:`${globalStyle.colors.primary}!important`,
    fontSize:16,
    marginTop:10,
    marginBottom:10,
    fontWeight:'bold',
    borderRadius:23,
    height:40,
    color:'#ffffff'
  }
};

const App = ( props ) => <AddInterest {...props} navigation={useNavigate()} />;

export default withStyles({...globalStyle.style,...styles})( App );
