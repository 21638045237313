
import padelBg from '../asset/image/sports/padel.jpeg';
import tennisBg from '../asset/image/sports/tennis.jpeg';
import voileBg from '../asset/image/sports/voile.jpeg';
import kayakBg from '../asset/image/sports/kayak.jpeg';
import aeroBg from '../asset/image/sports/aero.jpeg';
import badmintonBg from '../asset/image/sports/badminton.jpeg';
import squashBg from '../asset/image/sports/squash.jpeg';

export default {
  sports: {
    'tennis': {
      'img': tennisBg,
      'text': {
        'fr': 'Tennis'
      }
    },
    'tennis-padel': {
      'img': padelBg,
      'text': {
        'fr': 'Padel'
      }
    },
    'sailing': {
      'img': voileBg,
      'text': {
        'fr': 'Voile'
      }
    },
    'kayak': {
      'img': kayakBg,
      'text': {
        'fr': 'Kayak'
      }
    },
    'aero': {
      'img': aeroBg,
      'text': {
        'fr': 'Aéro'
      }
    },
    'badminton': {
      'img': badmintonBg,
      'text': {
        'fr': 'Badminton'
      }
    },
    'squash': {
      'img': squashBg,
      'text': {
        'fr': 'Squash'
      }
    },
  },
  services: [
    {
      sport: 'tennis',
      sportLib: 'Tennis',
      weather: 'outside',
      services : [
        { ref:'nearby_partners', title: {'fr': 'joueurs / joueuses'}, description:{ 'fr': `découvrez et contactez les joueurs et joueuses à proximité pour s'entrainer ou jouer des matchs` } },
        // { ref:'nearby_coaching', title: {'fr': 'cours particuliers'}, description:{ 'fr': `découvrez les coachs les plus proches pour prendre un cours particulier` } },
        // { ref:'nearby_stringers', title: {'fr': 'cordeurs'}, description:{ 'fr': `contactez les cordeurs les plus proches pour recorder votre raquette` } }
      ]
    },
    {
      sport: 'tennis-padel',
      sportLib: 'Padel',
      weather: 'outside',
      services : [
        { ref:'nearby_partners', title: {'fr': 'joueurs / joueuses'}, description:{ 'fr': `découvrez et contactez les joueurs et joueuses à proximité pour s'entrainer ou jouer des matchs` } },
      ]
    },
    {
      sport: 'badminton',
      sportLib: 'Badminton',
      weather: null,
      services : [
        { ref:'nearby_partners', title: {'fr': 'joueurs / joueuses'}, description:{ 'fr': `découvrez et contactez les joueurs et joueuses à proximité pour s'entrainer ou jouer des matchs` } }
      ]
    },
    {
      sport: 'squash',
      sportLib: 'Squash',
      weather: null,
      services : [
        { ref:'nearby_partners', title: {'fr': 'joueurs / joueuses'}, description:{ 'fr': `découvrez et contactez les joueurs et joueuses à proximité pour s'entrainer ou jouer des matchs` } }
      ]
    },
    {
      sport: 'sailing',
      weather: 'sailing',
      sportLib: 'Voile',
      services : [
        { ref:'nearby_partners', title: {'fr': 'navigateurs / navigatrices'}, description:{ 'fr': `découvrez et contactez les navigateurs à proximité pour effectuer une sortie en binome` } }
      ]
    },
    // {
    //   sport: 'kayak',
    //   sportLib: 'Kayak',
    //   services : [
    //     // { ref:'nearby_renting', title: {'fr': 'locations'}, description:{ 'fr': `trouvez des bateaux à louer autour de vous` } },
    //   ]
    // },
  ],
  days: [
    { ref:'mon', txt: { fr: 'L' } },
    { ref:'tue', txt: { fr: 'M' } },
    { ref:'wed', txt: { fr: 'M' } },
    { ref:'thu', txt: { fr: 'J' } },
    { ref:'fri', txt: { fr: 'V' } },
    { ref:'sat', txt: { fr: 'S' } },
    { ref:'sun', txt: { fr: 'D' } }
  ],
  periods: [
    { ref:'mor', txt: { fr: 'matin' } },
    { ref:'aft', txt: { fr: 'après-midi' } },
    { ref:'eve', txt: { fr: 'soirée' } },
  ],
  communitiesTypes : [
    {
      type: 'tennis-club',
      name: {
        fr: 'Club de tennis'
      }
    },
    {
      type: 'fanclub',
      name: {
        fr: 'Fanclub'
      }
    }
  ]
};