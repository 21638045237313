import React, { Component } from 'react';
import MainLayout from '../layout/main';
import withStyles from '@material-ui/core/styles/withStyles';
import Container from '@material-ui/core/Container';
import { useNavigate } from 'react-router';
import { useParams } from "react-router-dom";
import globalStyle from '../config/style';
import notFound from '../asset/image/404.gif';
import Button from '@material-ui/core/Button';

class NotFound extends Component {

  constructor (props) {
    super(props);
    this.state = {};
  }
  
  render = () => {

    const { classes } = this.props;

    const body = (
      <Container maxWidth="sm" style={{marginTop:25, marginBottom:35}}>
        <div style={{fontSize:42, textAlign:'center'}}>404</div>
        <div style={{fontSize:26, textAlign:'center'}}>page non trouvée :'(</div>
        <div style={{marginTop:35}}>
          <img style={{width:'100%'}} src={notFound} />
        </div>
        <div>
          <Button fullWidth className={classes.loginButton} onClick={() => this.props.navigation('/subscribes')}>ok</Button>
        </div>
      </Container>
    );

    return (<MainLayout hideFooter back={'/subscribes'} body={body} />);

	}

}

const styles = {};

const App = ( props ) => <NotFound {...props} params={useParams()} navigation={useNavigate()} />

export default withStyles({...globalStyle.style,...styles})( App );
