import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import globalStyle from '../config/style';
import withStyles from '@material-ui/core/styles/withStyles';
import { useNavigate } from 'react-router';
import { fetchGetURL } from '../utils/fetch';
import config from '../config/conf.js';

class UserBadge extends Component {

  constructor (props) {
    super(props);
    this.state = {
      loadingButton: false
    };
  }

  renderUserDisponibilities = (disponibilities) => {

    const output = [];
    const days = [
      { symbol: 'L', ref: 'mon' },
      { symbol: 'M', ref: 'tue' },
      { symbol: 'M', ref: 'wed' },
      { symbol: 'J', ref: 'thu' },
      { symbol: 'V', ref: 'fri' },
      { symbol: 'S', ref: 'sat' },
      { symbol: 'D', ref: 'sun' },
    ];

    days.forEach((day) => {

      if (disponibilities[day.ref]) {

        output.push(
          <div key={day.ref} style={{width:'14%', position:'relative', textAlign:'center', backgroundColor:'#d1edb7'}}>
            {day.symbol}
            <div style={{position:'absolute', display:'flex', marginLeft:'auto', left:0, right:0, marginRight:'auto', justifyContent:'space-between', flexDirection:'row', bottom:5, width:20}}>
              { disponibilities[day.ref].indexOf('mor') !== -1 ?
                <div style={{width:5, height:5, backgroundColor:'#000'}}></div> :
                <div style={{width:5, height:5, backgroundColor:'#BBB'}}></div>
              }
              { disponibilities[day.ref].indexOf('aft') !== -1 ?
                <div style={{width:5, height:5, backgroundColor:'#000'}}></div> :
                <div style={{width:5, height:5, backgroundColor:'#BBB'}}></div>
              }
              { disponibilities[day.ref].indexOf('eve') !== -1 ?
                <div style={{width:5, height:5, backgroundColor:'#000'}}></div> :
                <div style={{width:5, height:5, backgroundColor:'#BBB'}}></div>
              }
            </div>
          </div>
        )

      } else {

        output.push(
          <div key={day.ref} style={{width:'14%', textAlign:'center', backgroundColor:'#EEE', color:'#999'}}>{day.symbol}</div>
        );

      }
        
    });

    return (
      <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', height:40, lineHeight:'40px', fontWeight:'bold', marginTop:10}}>
        {output}
      </div>  
    );

  }

  renderCommonCommunities = (communities) => {

    if (!communities)
      return null;

    const communitiesArray = [];

    communities.forEach((community, key)=>{
      communitiesArray.push(
        <div key={key} style={{whiteSpace:'nowrap', fontSize:11, lineHeight:'13px', fontWeight:'bold', backgroundColor:'#000', color:'#fff', borderRadius:10, padding:5, paddingLeft:10, paddingRight:10, margin:3}}>{community.name}</div>
      );
    });

    if (communitiesArray.length > 0)
      return (
        <div style={{display:'flex', flexWrap:'wrap', borderRadius:10, marginTop:20, padding:10, backgroundColor:'#eee'}}>
          {communitiesArray}
        </div>
      );
    else
      return null;

  }

  renderUserTags = (tags) => {

    if (!tags)
      return null;

    const tagsArray = [];

    tags.forEach((tags, key)=>{
      tagsArray.push(
        <div key={key} style={{whiteSpace:'nowrap', fontSize:11, lineHeight:'13px', fontWeight:'bold', backgroundColor:'#000', color:'#fff', borderRadius:10, padding:5, paddingLeft:10, paddingRight:10, margin:3}}>{tags}</div>
      );
    });

    if (tagsArray.length > 0)
      return (
        <div style={{display:'flex', flexWrap:'wrap', borderRadius:10, marginTop:20, padding:10, backgroundColor:'#eee'}}>
          {tagsArray}
        </div>
      );
    else
      return null;

  }

  contactUser = async (userId, clubId, groupId) => {

    this.setState({loadingButton: true});

    const response = await fetchGetURL(`${config.api.base_url}${config.api.end_point.searchRoom}`, { type: 'private', userId, clubId, groupId });

    if (response.room) {
      this.setState({loadingButton: false});
      this.props.navigation(`/room/${response.room}`);
    }

  }

  formatDistanceTxt = (dist) => {

    const libs = {
      '0_5_km': 'situé à moins de 5 kilomètres de vous',
      '5_10_km': 'situé entre 5 et 10 kilomètres de vous',
      '10_20_km': 'situé entre 10 et 20 kilomètres de vous',
      '20_30_km': 'situé entre 20 et 30 kilomètres de vous',
      '30_40_km': 'situé entre 30 et 40 kilomètres de vous',
      '40_50_km': 'situé entre 40 et 50 kilomètres de vous',
      '50_km': 'situé à plus de 50 kilomètres de vous',
    }

    if (libs[dist])
      return libs[dist];

    return '';

  }

  contactUserAction = (badge) => {

    if (badge.group)
      return this.contactUser(badge.id, badge.group.clubId, badge.group.id);
    
    return this.contactUser(badge.id, false, false);

  }

  render = () => {

    const badge = this.props.badge;
    const { classes } = this.props;
    const token = localStorage.getItem('AuthToken');

    return (<div key={badge.id} style={{display:'flex', flexDirection:'column', borderRadius:'15px', boxShadow:'5px 10px 20px 3px #DDD', backgroundColor:'#fff', padding:15, marginBottom:25}}>
            
      <div style={{display:'flex', flexDirection:'row', fontFamily:'Poppins-Bold', fontSize:22, lineHeight:'22px'}}>
        {badge.firstname} {badge.lastname}
      </div>

      { badge.group && badge.group.club && <div style={{fontFamily:'Poppins-Bold', letterSpacing:-1, textTransform:'uppercase', color:'#AAA', lineHeight:'14px', fontSize:14, marginTop:5, marginBottom:5}}>
        {badge.group.name} - {badge.group.club.name}
      </div> }

      { badge.distance && <div style={{fontFamily:'Poppins-Bold', letterSpacing:-1, textTransform:'uppercase', color:'#AAA', lineHeight:'14px', fontSize:14, marginTop:5, marginBottom:5}}>
        { badge.sport && `${badge.sport} - ` }{this.formatDistanceTxt(badge.distance)}
      </div> }

      { this.renderUserDisponibilities(badge.disponibilities) }
      { this.renderUserTags(badge.tags) }
      { this.renderCommonCommunities(badge.communities) }

      { (token && this.props.showContactButton) && <div style={{display:'flex', marginTop:20, flexDirection:'row'}}>
        <Button
          className={classes.contactUserButton}
          type="submit"
          fullWidth
          variant="contained"
          onClick={() => this.contactUserAction(badge)}
        >
          { this.state.loadingButton ? <CircularProgress size={20} style={{color:'#fff'}} /> : "Contacter" }
        </Button>
      </div> }

    </div>);

  }

}

const styles = {
  contactUserButton: {
    backgroundColor:`${globalStyle.colors.primary}!important`,
    fontSize:14,
    fontWeight:'bold',
    borderRadius:23,
    height:30,
    color:'#ffffff'
  }
};

const App = ( props ) => <UserBadge {...props} navigation={useNavigate()} />;

export default withStyles({...globalStyle.style,...styles})( App );