import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import globalStyle from '../config/style';
import withStyles from '@material-ui/core/styles/withStyles';
import { useNavigate } from 'react-router';
import { fetchGetURL } from '../utils/fetch';
import config from '../config/conf.js';

class Hint extends Component {

  constructor (props) {
    super(props);
    this.state = {};
  }

  renderTennis = () => {

    const { classes } = this.props;

    return (
      <div style={{marginTop:70}}>
        <div style={{fontFamily:'Poppins-Bold', textTransform:'uppercase', fontSize:32, marginLeft:20, marginBottom:-10, color:'#DDD', letterSpacing:-1}}>astuce</div>
        <div style={{display:'flex', flexDirection:'column', borderRadius:'15px', boxShadow:'5px 10px 20px 3px #DDD', backgroundColor:'#fff', padding:15, marginBottom:15}}>
          <div style={{fontFamily:'Poppins-Bold', textAlign:'center', fontSize:18, lineHeight:'20px', textTransform:'uppercase', letterSpacing:-1, color:'#555'}}>Vous êtes team Rafa, Roger ou Djoko ?</div>
          <div style={{fontFamily:'Poppins-Bold', textAlign:'center', fontSize:14, lineHeight:'16px', textTransform:'uppercase', marginTop:10, letterSpacing:-0.5, color:'#999'}}>Quand vous recherchez des partenaires à proximité, vos communautés en commun seront mises en avant. Rejoignez vos clubs, universités ou même fanclubs.</div>
          <div>
          <Button
            className={classes.button}
            type="submit"
            fullWidth
            variant="contained"
            onClick={() => { this.props.navigation('/parameters') }}
          >
            rejoindre des communautés
          </Button>
          </div>
        </div>
      </div>
    );

  }

  renderSailing = () => {

    const { classes } = this.props;

    return (
      <div style={{marginTop:70}}>
        <div style={{fontFamily:'Poppins-Bold', textTransform:'uppercase', fontSize:32, marginLeft:20, marginBottom:-10, color:'#DDD', letterSpacing:-1}}>astuce</div>
        <div style={{display:'flex', flexDirection:'column', borderRadius:'15px', boxShadow:'5px 10px 20px 3px #DDD', backgroundColor:'#fff', padding:15, marginBottom:15}}>
          <div style={{fontFamily:'Poppins-Bold', textAlign:'center', fontSize:18, lineHeight:'20px', textTransform:'uppercase', letterSpacing:-1, color:'#555'}}>Vous faites partie d'un club de voile ?</div>
          <div style={{fontFamily:'Poppins-Bold', textAlign:'center', fontSize:14, lineHeight:'16px', textTransform:'uppercase', marginTop:10, letterSpacing:-0.5, color:'#999'}}>Quand vous recherchez des partenaires à proximité, vos communautés en commun seront mises en avant. Rejoignez vos clubs, universités ou même fanclubs.</div>
          <div>
          <Button
            className={classes.button}
            type="submit"
            fullWidth
            variant="contained"
            onClick={() => { this.props.navigation('/parameters') }}
          >
            rejoindre des communautés
          </Button>
          </div>
        </div>
      </div>
    );

  }

  render = () => {

    if (this.props.sport === 'tennis')
      return this.renderTennis();
    else if (this.props.sport === 'sailing')
      return this.renderSailing();

    return null;

  }

}

const styles = {
  button: {
    backgroundColor:`${globalStyle.colors.primary}!important`,
    fontSize:14,
    fontWeight:'bold',
    borderRadius:23,
    height:35,
    color:'#ffffff',
    marginTop:20
  }
};

const App = ( props ) => <Hint {...props} navigation={useNavigate()} />;

export default withStyles({...globalStyle.style,...styles})( App );