import React, { Component } from 'react';
import MainLayout from '../layout/main';
import withStyles from '@material-ui/core/styles/withStyles';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import { LocalPhone, AlternateEmail, Sms, ChevronLeft, ChevronRight } from '@mui/icons-material';
import Link from '@material-ui/core/Link';
import Carousel from 'react-material-ui-carousel';
import globalStyle from '../config/style';

class MyActivities extends Component {

  constructor (props) {
    super(props);
    this.state = {
      showActivityDetails: false
    };
  }

  componentDidMount = async () => {
    document.title = "Happy Sports - Créer une annonce";
  }

  renderActivity = () => {

    return (
      <div onClick={() => { this.setState({showActivityDetails: true}) }} style={{display:'flex', flexDirection:'column', borderRadius:'15px', cursor:'pointer', boxShadow:'5px 10px 20px 3px #DDD', backgroundColor:'#fff', padding:15, marginBottom:25}}>
        <div style={{display:'flex', flexDirection:'row'}}>
          <div style={{width:65, textAlign:'center', display:'flex', flexDirection:'column', borderRight:'solid 1px #428892', marginRight:15}}>
            <div style={{fontSize:16, lineHeight:'18px', color:'#777', letterSpacing:2}}>Jeu</div>
            <div style={{fontWeight:'bolder', fontSize:28, lineHeight:'32px', fontFamily:'Poppins-Bold'}}>20</div>
            <div style={{fontSize:16, lineHeight:'18px', color:'#777', letterSpacing:2}}>Juin</div>
          </div>
          <div style={{display:'flex', flexDirection:'column'}}>
            <div style={{fontFamily:'Poppins-Bold', lineHeight:'24px', fontSize:20}}>Cours Collectif</div>
            <div style={{fontSize:16, lineHeight:'22px', color:'#666', letterSpacing:1}}>14:00</div>
            <div style={{fontSize:16, lineHeight:'22px', fontWeight:'bolder', letterSpacing:2}}>15€</div>
          </div>
        </div>
        <div style={{display:'flex', flexDirection:'row', justifyContent:'center', marginTop:15, marginBottom:10, fontSize:14}}>
          <div style={{fontFamily:'Poppins-Bold', fontSize:16, fontWeight:'bold', color:'#ef0707'}}>2 réponses en attente</div>
        </div>
        <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', marginTop:10, fontSize:14, textAlign:'right'}}>
          <div style={{fontWeight:'bold'}}>ASSM Saint Médard</div>
          <div>créé par Alain</div>
        </div>
      </div>
    );

  }

  carouselNavButton = ({onClick, className, style, next, prev}) => {
    if (next)
      return (
        <div style={{height:40, marginTop:130, marginRight:'-15px'}} onClick={onClick}>
          {next && <ChevronRight style={{cursor:'pointer', fontSize:52, marginLeft:'-15px'}} />}
        </div>
      )
    else
      return (
        <div style={{height:40, marginTop:130, marginLeft:'-15px'}} onClick={onClick}>
          {prev && <ChevronLeft style={{cursor:'pointer', fontSize:52}} />}
        </div>
      )
  }

  renderCarousel = () => {

    const { classes } = this.props;

    var items = [
      {
        name: "Paul Danilo"
      },
      {
        name: "John Doe"
      },
      {
        name: "John Smith"
      }
    ]

    return (
      <Carousel NavButton={this.carouselNavButton} indicators={false} autoPlay={false} navButtonsAlwaysVisible={true} cycleNavigation={false} animation={'slide'}>{
        items.map( (item, i) => <div style={{border:'solid 2px #DDD', padding:15, paddingBottom:5, marginLeft:25, marginRight:25, borderRadius:'10px', marginTop:20}}>
        <div style={{display:'flex', justifyContent:'center'}}>
          <div style={{fontSize:24, lineHeight:'22px', fontFamily:'Poppins-Bold'}}>{item.name}</div>
        </div>
        <div style={{display:'flex', marginTop:10, justifyContent:'space-evenly'}}>
          <Button
            className={classes.buttonContact}
            startIcon={<Sms />}
            fullWidth
            type="submit"
            variant="contained"
            onClick={() => {}}
          >
            0633954434 (SMS)
          </Button>
        </div>
        <div style={{display:'flex', justifyContent:'space-evenly'}}>
          <Button
            className={classes.buttonContact}
            startIcon={<LocalPhone />}
            fullWidth
            type="submit"
            variant="contained"
            onClick={() => {}}
          >
            0633954434 (Appel)
          </Button>
        </div>
        <div style={{display:'flex', justifyContent:'space-evenly'}}>
          <Button
            className={classes.buttonContact}
            startIcon={<AlternateEmail />}
            fullWidth
            type="submit"
            variant="contained"
            onClick={() => {}}
          >
            email
          </Button>
        </div>
        <div style={{display:'flex', justifyContent:'space-evenly', marginBottom:20, marginTop:25}}>
          <div>
            <Button
              className={classes.buttonAccept}
              type="submit"
              variant="contained"
              onClick={() => {}}
            >
              accepter
            </Button>
          </div>
          <div>
            <Button
              className={classes.buttonRefuse}
              type="submit"
              variant="contained"
              onClick={() => {}}
            >
              refuser
            </Button>
          </div>
        </div>
        <div style={{fontSize:12, textAlign:'center', color:'#999', marginTop:15}}>demande envoyée le 12 janv. à 14:43</div>
      </div> )
      }</Carousel>
    )

  }

  render = () => {

    const { classes } = this.props;

    const body = (
      <Container maxWidth="sm" style={{marginTop:25, marginBottom:65}}>
        <Link href="/create">
          <Button
            className={classes.createActivityButton}
            type="submit"
            fullWidth
            variant="contained"
            onClick={() => {}}
          >
            Créer une annonce
          </Button>
        </Link>

        <div style={{marginBottom:35}}>
          <div style={{fontFamily:'Poppins-Bold', fontSize:22, marginBottom:10, paddingLeft:10}}>Mes annonces actives</div>
          { this.renderActivity() }
          <div style={{fontSize:16, textAlign:'center', color:'#666', marginTop:20}}>il n'y a aucune annonce active</div>
        </div>

        <div style={{marginBottom:35}}>
          <div style={{fontFamily:'Poppins-Bold', fontSize:22, marginBottom:10, paddingLeft:10}}>Autres annonces</div>
          { this.renderActivity() }
          <div style={{fontSize:16, textAlign:'center', color:'#666', marginTop:20}}>il n'y a aucune annonce active</div>
        </div>

        <SwipeableDrawer
          style={{borderRadius:20}}
          anchor={'bottom'}
          open={this.state.showActivityDetails}
          onClose={() => { this.setState({'showActivityDetails': false}) }}
          onOpen={() => {}}
        >
          <Container maxWidth="sm" style={{marginTop:20, marginBottom:20}}>

            <Button
              style={{marginTop:20}}
              className={classes.button}
              type="submit"
              fullWidth
              variant="contained"
              onClick={() => {}}
            >
              modifier l'annonce
            </Button>

            {this.renderCarousel()}

          </Container>
        </SwipeableDrawer>

      </Container>
    );

    return (<MainLayout body={body} />);

	}

}

const styles = {
  buttonContact: {
    backgroundColor:'#888',
    fontSize:14,
    fontWeight:'bold',
    borderRadius:10,
    height:35,
    color:'#ffffff',
    marginTop:10
  },
  createActivityButton: {
    backgroundColor:globalStyle.colors.primary,
    fontSize:16,
    marginBottom:35,
    fontWeight:'bold',
    borderRadius:23,
    height:45,
    color:'#ffffff'
  },
  button: {
    backgroundColor:globalStyle.colors.primary,
    fontSize:16,
    fontWeight:'bold',
    borderRadius:23,
    height:45,
    color:'#ffffff'
  },
  buttonAccept: {
    backgroundColor:'green',
    fontSize:14,
    fontWeight:'bold',
    borderRadius:13,
    height:26,
    color:'#ffffff'
  },
  buttonRefuse: {
    backgroundColor:'#ef0707',
    fontSize:14,
    fontWeight:'bold',
    borderRadius:13,
    height:26,
    color:'#ffffff'
  },
  carousel:{
    buttonWrapper: {
      position: "absolute",
      height: "100px",
      backgroundColor: "transparent",
      top: "calc(50% - 70px)"
    },
    fullHeightHoverWrapper: {
      height: "100%",
      top: "0"
    },
    buttonVisible:{
      opacity: "1"
    },
    buttonHidden:{
      opacity: "0",
    },
    button: {
      margin: "0",
      position: "relative",
      top: "calc(50% - 20px) !important",
      color: "red",
      cursor: "pointer"
    },
    next: {
      right: 100
    },
    prev: {
      left: 110
    }
  }
};

export default withStyles({...globalStyle.style,...styles})(MyActivities);
