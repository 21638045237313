import React, { Component } from 'react';
import MainLayout from '../layout/main';
import withStyles from '@material-ui/core/styles/withStyles';
import Container from '@material-ui/core/Container';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Settings as SettingsIcon } from '@mui/icons-material';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useNavigate } from 'react-router';
import GroupSettings from "../component/group-settings";
import AddSport from "../component/add-sport";
import InstallTutorial from "../component/install-tutorial";
import Hint from "../component/hint";
import SportFilter from "../component/sport-filter";
import { fetchGetURLWithCache } from '../utils/cache';
import { isIos } from '../utils/common';
import globalStyle from '../config/style';
import config from '../config/conf.js';
import appConfig from '../config/application.js';
import { setStateAsync } from '../utils/common';
import Link from '@material-ui/core/Link';
import { Facebook, Instagram, Twitter, LinkedIn } from '@mui/icons-material';

class Subscribes extends Component {

  constructor (props) {
    super(props);
    this.state = {
      joinGroupModal: false,
      loading: true,
      invitationCode: '',
      groups: [],
      sports: [],
      showSettingsModal: false,
      selectedSport: null,
      selectedGroup: {
        groupId: null,
        clubId: null
      },
      selectedMode: 'nearby',
      showIosTutorial: false,
      showIosTutorialModal: false,
      showAddSportModal: false,
      forceAddSport: false
    };
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  componentDidMount = async () => {

    document.title = "Happy Sports";
    await this.refreshUserGroups();
    await setStateAsync({ showIosTutorial: isIos(navigator.userAgent) }, this);

  }

  availableServicesForTheSelectedSport = () => {

    const servicesConf = appConfig.services;
    let selectedSportServices = [];

    if ( servicesConf.filter(service => service.sport === this.state.selectedSport)[0] )
      selectedSportServices = servicesConf.filter(service => service.sport === this.state.selectedSport)[0].services;

    return selectedSportServices;

  }

  selectSport = async (selectedSport) => {

    await setStateAsync({selectedSport}, this);

    let selectedMode = 'groups';

    if (this.availableServicesForTheSelectedSport().length > 0)
      selectedMode = 'nearby';

    await setStateAsync({selectedSport, selectedMode}, this);

  }

  refreshUserGroups = async () => {

    const response = await fetchGetURLWithCache('userGroups', `${config.api.base_url}${config.api.end_point.userGroups}`, {}, {}, true);
    await setStateAsync({groups: response.groups, sports: response.sports, 'loading': false}, this);
    
  }

  getSettingsGroup = (groupId) => {

    const groups = this.state.groups;
    const selectedGroup = groups.filter( group => group.id === groupId );

    if (selectedGroup[0])
      return selectedGroup[0];

    return null;

  }

  showGroupSettings = (groupId) => {

    const selectedGroup = this.getSettingsGroup(groupId);

    if (selectedGroup)
      this.setState({
        selectedGroup: {
          groupId: selectedGroup.id,
          clubId: selectedGroup.club.id
        },
        showSettingsModal: true
      });

  }

  toggleSettingsModal = (bool) => {

    this.setState({
      showSettingsModal: bool
    });

  }

  toggleTutorialModal = (bool) => {

    this.setState({
      showIosTutorialModal: bool
    });

  }

  toggleAddSportModal = (bool, forceAddSport) => {

    if (!forceAddSport)
      forceAddSport = false;

    this.setState({
      showAddSportModal: bool,
      forceAddSport: forceAddSport
    });

  }

  nearbyPartners = () => {
    this.props.navigation(`/nearby/partners/${this.state.selectedSport}`);
  }

  renderGroups = () => {

    const { classes } = this.props;
    let groups = [];
    let buttonLabel = ``;

    if ( this.state.groups.length > 0 ) {

      this.state.groups.forEach((group) => {
        if (!this.state.selectedSport || this.state.selectedSport === group.sport) {

          if ((group.usersLength-1) <= 1)
            buttonLabel = `Voir le groupe`;
          else
            buttonLabel = `Voir les ${(group.usersLength-1)} membres`;

          groups.push(
            <div key={group.id} style={{display:'flex', flexDirection:'column', borderRadius:'15px', boxShadow:'5px 10px 20px 3px #DDD', backgroundColor:'#fff', padding:15, marginTop:15, marginBottom:15}}>
              <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', height:20}}>
                <div onClick={() => this.props.navigation(`/group/${group.club.id}/${group.id}/`)} style={{fontFamily:'Poppins-Bold', letterSpacing:-1, textTransform:'uppercase', lineHeight:'22px', fontSize:20}}>
                  {group.name}
                </div>
                <div onClick={() => { this.showGroupSettings(group.id) }}>
                  <SettingsIcon style={{fontSize:20, color:'#000', lineHeight:'20px'}} />
                </div>
              </div>
              <div style={{fontFamily:'Poppins-Bold', letterSpacing:-1, textTransform:'uppercase', color:'#AAA', lineHeight:'14px', fontSize:14, marginTop:5}}>
                {group.club.name}
              </div>
              <div style={{marginTop:15, marginBottom:10}}>
                <Button
                  className={classes.leaveGroupButton}
                  type="submit"
                  fullWidth
                  variant="contained"
                  onClick={() => this.props.navigation(`/group/${group.club.id}/${group.id}/`)}
                >
                  {buttonLabel}
                </Button>
              </div>
            </div>
          );

        }
      });

    }
    
    if (groups.length === 0) {

      groups = (<div>
        <div style={{textAlign:'center', color:'#999', lineHeight:'20px', fontSize:18, textTransform:'uppercase', fontFamily:'Poppins-Bold', marginTop:25, letterSpacing:-1}}>aucun groupe</div>
        <div style={{textAlign:'center', color:'#AAA', lineHeight:'18px', fontSize:16, textTransform:'uppercase', fontFamily:'Poppins-Bold', marginTop:15, letterSpacing:-1}}>utilisez les groupes pour simplifier la prise de contact au sein de votre club</div>
      </div>)

    }

    const servicesOutput = [];

    if (this.state.sports.includes(this.state.selectedSport)) {

      this.availableServicesForTheSelectedSport().forEach((service) => {

        let callback;

        if (service.ref === 'nearby_partners')
          callback = this.nearbyPartners;

        servicesOutput.push(
          <div key={service.ref} style={{display:'flex', flexDirection:'column', borderRadius:'15px', boxShadow:'5px 10px 20px 3px #DDD', backgroundColor:'#fff', padding:15, marginTop:15, marginBottom:15}}>
            <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', height:20}}>
              <div onClick={() => {}} style={{fontFamily:'Poppins-Bold', letterSpacing:-1, textTransform:'uppercase', lineHeight:'20px', fontSize:19}}>
                { service.title['fr'] }
              </div>
            </div>
            <div style={{fontFamily:'Poppins-Bold', letterSpacing:-1, textTransform:'uppercase', color:'#AAA', lineHeight:'16px', fontSize:15, marginTop:5}}>
              { service.description['fr'] }
            </div>
            <div style={{marginTop:15, marginBottom:10}}>
              <Button
                className={classes.leaveGroupButton}
                type="submit"
                fullWidth
                variant="contained"
                onClick={() => { callback() }}
              >
                voir
              </Button>
            </div>
          </div>
        );

      });

    } else {

      servicesOutput.push(
        <div key={'settings'} style={{display:'flex', flexDirection:'column', borderRadius:'15px', boxShadow:'5px 10px 20px 3px #DDD', backgroundColor:'#fff', padding:15, marginTop:15, marginBottom:15}}>
          
          <div style={{fontFamily:'Poppins-Bold', letterSpacing:-1, textTransform:'uppercase', color:'#AAA', lineHeight:'16px', fontSize:15, marginTop:5}}>
            trouvez des sportifs à proximité de votre position
          </div>
          <div style={{marginTop:15, marginBottom:10}}>
            <Button
              className={classes.leaveGroupButton}
              type="submit"
              fullWidth
              variant="contained"
              onClick={() => { this.toggleAddSportModal(true, false) }}
            >
              configurer
            </Button>
          </div>
        </div>
      );

    }

    const styleSelected = {borderBottom:'solid 2px #666', color:'#555'};
    const styleUnselected = {color:'#BBB'};
    const commonStyle = {paddingLeft:15, paddingRight:15};
    let groupTabStyle, nearbyTabStyle;

    if (this.state.selectedMode === 'groups') {
      groupTabStyle = { ...styleSelected, ...commonStyle };
      nearbyTabStyle = { ...styleUnselected, ...commonStyle };
    } else {
      groupTabStyle = { ...styleUnselected, ...commonStyle };
      nearbyTabStyle = { ...styleSelected, ...commonStyle };    
    }

    return (
      <div>{ servicesOutput }</div>
    );

  }

  handleSwitchTab = (selectedMode) => {
    this.setState({selectedMode})
  }

  joinGroup = () => {
    const invitCode = this.state.invitationCode;
    if (invitCode)
      this.props.navigation(`/join/${invitCode}`);
  }

  socialNetwork = () => {

    return (
      <div>
        <div style={{display:'flex', justifyContent:'center', marginTop:5}}>
          <Link target={"_blank"} style={{color:'#CCC'}} href={config.links.linkedin}> <LinkedIn style={{fontSize:32, margin:5}} /> </Link>
          <Link target={"_blank"} style={{color:'#CCC'}} href={config.links.facebook}> <Facebook style={{fontSize:32, margin:5}} /> </Link>
          <Link target={"_blank"} style={{color:'#CCC'}} href={config.links.instagram}> <Instagram style={{fontSize:32, margin:5}} /> </Link>
          <Link target={"_blank"} style={{color:'#CCC'}} href={config.links.twitter}> <Twitter style={{fontSize:32, margin:5}} /> </Link>
        </div>
      </div>
    );

  }

  renderInvitationBox = () => {

    const { classes } = this.props;

    return (
      <SwipeableDrawer
        style={{borderRadius:20}}
        anchor={'bottom'}
        open={this.state.joinGroupModal}
        onClose={() => { this.setState({'joinGroupModal': false}) }}
        onOpen={() => {}}
      >
        <Container maxWidth="sm" style={{marginTop:20, marginBottom:20}}>
          <div style={{padding:10, textAlign:'center'}}>
            <div style={{marginBottom:20, fontWeight:'bold', fontSize:20, lineHeight:'22px'}}>entrez un code invitation pour rejoindre un groupe</div>
            <div style={{marginBottom:20, fontWeight:'bold', color:'#999', fontSize:16, lineHeight:'18px'}}>les groupes permettent aux adhérents d'un club de se contacter facilement</div>
            <TextField
              className={classes.commonTextField}
              variant="outlined"
              fullWidth
              id="invitationCode"
              name="invitationCode"
              label="code (ex: YHG56T)"
              onChange={this.handleChange}
            />
            <Button
              style={{marginTop:20}}
              className={classes.validJoinGroupButton}
              type="submit"
              fullWidth
              variant="contained"
              onClick={this.joinGroup}
            >
              rejoindre
            </Button>
          </div>
        </Container>
      </SwipeableDrawer>
    );

  }

  render = () => {

    const { classes } = this.props;

    const body = (
      <Container maxWidth="sm" style={{marginTop:25, marginBottom:65}}>

        { this.state.loading ? <div style={{textAlign:"center", marginTop:50}}><CircularProgress style={{color:"red"}} /></div> :
          <div>
            <SportFilter setAddSportModal={this.toggleAddSportModal} selectSport={this.selectSport} selectedSport={this.state.selectedSport} groups={this.state.groups} sports={this.state.sports} />
            { this.state.groups.length > 0 || this.state.sports.length > 0 ? this.renderGroups() :
              <div>
                <div style={{textAlign:'center', color:'#333', lineHeight:'36px', fontSize:32, textTransform:'uppercase', fontFamily:'Poppins-Bold', letterSpacing:-2}}>Bienvenue sur<br/>Happy Sports</div>
                <div style={{textAlign:'center', color:'#999', lineHeight:'22px', fontSize:18, textTransform:'uppercase', fontFamily:'Poppins-Bold', marginTop:25, letterSpacing:-1}}>configurez vos sports favoris pour commencer à rencontrer de nouvelles personnes et enrichir votre pratique sportive</div>
                <div style={{marginTop:25}}>
                <Button
                  className={classes.createClubButton}
                  type="submit"
                  fullWidth
                  variant="contained"
                  onClick={() => { this.setState({'showAddSportModal': true}) }}
                >
                  ajouter un sport
                </Button>
                </div>
              </div>
            }
          </div>
        }

        <Hint sport={this.state.selectedSport} />

        { this.state.showIosTutorial && <div onClick={() => this.toggleTutorialModal(true)} style={{marginTop:50, textAlign:'center', color:'#999', fontSize:'15px', fontFamily:'Poppins-Bold'}}>installer l'application</div> }

        <div style={{marginTop:50}}>
          {this.socialNetwork()}
        </div>

        { this.state.showSettingsModal && <GroupSettings
          group={this.getSettingsGroup(this.state.selectedGroup.groupId)}
          show={this.state.showSettingsModal}
          setModalStatus={this.toggleSettingsModal} /> }

        { this.state.showIosTutorialModal && <InstallTutorial
          show={this.state.showIosTutorialModal}
          setModalStatus={this.toggleTutorialModal} /> }

        { this.state.showAddSportModal && <AddSport
          sport={this.state.forceAddSport ? null : this.state.selectedSport}
          showModal={this.state.showAddSportModal}
          setModalStatus={this.toggleAddSportModal} /> }

      </Container>
    );

    return (<MainLayout body={body} />);

	}

}

const styles = {
  validJoinGroupButton: {
    backgroundColor:`${globalStyle.colors.primary}!important`,
    fontSize:16,
    fontWeight:'bold',
    borderRadius:23,
    height:45,
    color:'#ffffff'
  },
  leaveGroupButton: {
    backgroundColor:`${globalStyle.colors.primary}!important`,
    fontSize:14,
    fontWeight:'bold',
    borderRadius:23,
    height:30,
    color:'#ffffff'
  },
  createClubButton: {
    backgroundColor:`${globalStyle.colors.primary}!important`,
    fontSize:16,
    marginBottom:10,
    fontWeight:'bold',
    borderRadius:23,
    height:35,
    color:'#ffffff'
  }
};

const App = ( props ) => <Subscribes {...props} navigation={useNavigate()} />;

export default withStyles({...globalStyle.style,...styles})( App );
