import { fetchGetURL } from '../utils/fetch';
import config from '../config/conf'

export const validateEmail = email => {
  var reg = /^([A-Za-z0-9_\-\.])+@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
  if (reg.test(email) === false) {
    return false;
  }
  return true;
};

export const asyncForEach = async (array, callback) => {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}

export const isset = variable => {
  if (typeof variable !== 'undefined')
    return true
  else
    return false
}

export const setStateAsync = (state, that) => {
  return new Promise((resolve) => {
    that.setState(state, resolve);
  });
}

export const isConnected = () => {

  return new Promise(async (resolve, reject) => {

    try {

      if ( !localStorage.getItem('AuthToken') || !localStorage.getItem('UserId') )
        resolve(false);

      const getCheckToken = `${config.api.domain}${config.api.methods.get.checkToken}`;
      const checkToken = await fetchGetURL(getCheckToken);

      if (checkToken.error)
        resolve(false);

      resolve(true);

    } catch (err) {
      reject(err);
    }

  });

}

export const checkToken = (history) => {

  return new Promise(async (resolve, reject) => {

    try {

      if ( !localStorage.getItem('AuthToken') || !localStorage.getItem('UserId') ) {
        history.push(`/login`)
      }

      const getCheckToken = `${config.api.domain}${config.api.methods.get.checkToken}`;
      const checkToken = await fetchGetURL(getCheckToken);

      if (checkToken.error) {
        localStorage.removeItem('AuthToken');
        localStorage.removeItem('UserId');
        localStorage.removeItem('user');
        history.push(`/login`);
      }

      resolve();

    } catch (err) {
      reject(err);
    }

  });

}

export const autoLogin = (history) => {

  return new Promise(async (resolve, reject) => {

    try {

      if ( localStorage.getItem('AuthToken') && localStorage.getItem('UserId') ) {
        
        const getCheckToken = `${config.api.domain}${config.api.methods.get.checkToken}`;
        const checkToken = await fetchGetURL(getCheckToken);

        if (!checkToken.error) {
          history.push(`/`);
        }
        
      }

      resolve();

    } catch(error) {
      reject(error);
    }

  });

};

export const isIos = (userAgent) => {

  if ( userAgent.includes(`iPhone`) || userAgent.includes(`iPad`) )
    return true;

  return false;

}

export const getFormattedDate = (ts) => {

  const date = new Date(ts*1000);

  let month = date.getMonth() + 1;
  let day = date.getDate();
  let hour = date.getHours();
  let min = date.getMinutes();
  let dayOfWeek = date.getDay();

  const days = {
    0: 'dim.',
    1: 'lun.',
    2: 'mar.',
    3: 'mer.',
    4: 'jeu.',
    5: 'ven.',
    6: 'sam.',
  };

  const monthsTxt = {
    1: 'jan.',
    2: 'fév.',
    3: 'mar.',
    4: 'avr.',
    5: 'mai.',
    6: 'juin.',
    7: 'juil.',
    8: 'aoû.',
    9: 'sep.',
    10: 'oct.',
    11: 'nov.',
    12: 'déc.',
  };

  day = (day < 10 ? "0" : "") + day;
  hour = (hour < 10 ? "0" : "") + hour;
  min = (min < 10 ? "0" : "") + min;

  dayOfWeek = days[dayOfWeek];
  month = monthsTxt[month];

  return `${dayOfWeek} ${day} ${month} ${hour}:${min}`;

}

export const sortDictionnaryByKey = (dictionnary) => {

  return Object.keys(dictionnary).sort().reduce(
    (obj, key) => { 
      obj[key] = dictionnary[key]; 
      return obj;
    }, 
    {}
  );

}