import React, { Component } from 'react';
import MainLayout from '../layout/main';
import withStyles from '@material-ui/core/styles/withStyles';
import Container from '@material-ui/core/Container';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import globalStyle from '../config/style';

class EditGroup extends Component {

  constructor (props) {
    super(props);
    this.state = {};
  }

  componentDidMount = async () => {
    document.title = "Happy Sports";
  }

  renderUserList = () => {

    const { classes } = this.props;

    return (
      <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', borderRadius:'15px', boxShadow:'5px 10px 20px 3px #DDD', backgroundColor:'#fff', padding:15, marginBottom:15}}>
        <div style={{display:'flex', flexDirection:'column'}}>
          <div style={{fontFamily:'Poppins-Bold', fontSize:22}}>Paul Danilo</div>
          <div style={{fontSize:12, color:'#999'}}>membre depuis le 14 janv. 2022</div>
        </div>
        <div style={{display:'flex', flexDirection:'column'}}>
          <div style={{height:32, textAlign:'right'}}><Switch className={classes.commonSwitch} /></div>
          <div style={{fontSize:12, color:'#999'}}>modérateur</div>
        </div>
      </div>
    );

  }

  render = () => {

    const { classes } = this.props;

    const top100Films = [
      { name: 'Tennis', id: 'tennis' },
      { name: 'Padel-Tennis', id: 'padel-tennis' },
      { name: 'Voile', id: 'voile' },
      { name: 'Kayak', id: 'kayak' },
    ];

    const body = (
      <Container maxWidth="sm" style={{marginTop:25, marginBottom:65}}>

        <div style={{fontFamily:'Poppins-Bold', fontSize:22}}>Informations</div>
        
        <div style={{marginBottom:25}}>

          <TextField
            variant="outlined"
            className={classes.commonTextField}
            fullWidth
            id="password"
            label="Nom du club"
            name="password"
            style={{marginTop:25, marginBottom:25}}
          />

          <Autocomplete
            multiple
            className={classes.commonAutoComplete}
            options={top100Films}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField {...params} label="Sports" />
            )}
          />

          <Button
            className={classes.button}
            type="submit"
            fullWidth
            variant="contained"
            onClick={() => {}}
          >
            Enregistrer
          </Button>

        </div>

        <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', marginBottom:10, marginTop:60}}>
          <div style={{fontFamily:'Poppins-Bold', fontSize:22, lineHeight:'24px'}}>Utilisateurs</div>
        </div>

        <div style={{fontSize:16, textAlign:'center', color:'#666', marginTop:30, marginBottom:30, lineHeight:'16px'}}>
          désignez comme modérateur les utilisateurs pouvant créer des activités
        </div>

        <div>
          {this.renderUserList()}
          {this.renderUserList()}
        </div>

      </Container>
    );

    return (<MainLayout body={body} />);

	}

}

const styles = {
  buttonContact: {
    backgroundColor:globalStyle.colors.primary,
    fontSize:14,
    fontWeight:'bold',
    borderRadius:10,
    height:35,
    color:'#ffffff',
    marginTop:10
  },
  button: {
    backgroundColor:globalStyle.colors.primary,
    fontSize:16,
    fontWeight:'bold',
    borderRadius:23,
    height:45,
    color:'#ffffff',
    marginTop:35
  }
};

export default withStyles({...globalStyle.style,...styles})(EditGroup);
