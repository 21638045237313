import React, { Component } from 'react';
import MainLayout from '../layout/main';
import withStyles from '@material-ui/core/styles/withStyles';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useNavigate } from 'react-router';
import { fetchGetURL } from '../utils/fetch';
import globalStyle from '../config/style';
import config from '../config/conf.js';
import { getFormattedDate } from '../utils/common';

class Messenger extends Component {

  constructor (props) {
    super(props);
    this.state = {
      loading: true,
      rooms: []
    };
  }

  refreshRooms = async () => {

    const httpResponse = await fetchGetURL(`${config.api.base_url}${config.api.end_point.rooms}`, {}, {}, true);
    this.setState({rooms: httpResponse, loading:false});

  }

  componentDidMount = async () => {
    document.title = "Messages";
    this.refreshRooms();
  }

  renderConversations = () => {

    const rooms = [];

    this.state.rooms.forEach((room) => {
      rooms.push(
        <div key={room.id} onClick={() => this.props.navigation(`/room/${room.id}`)} style={{display:'flex', flexDirection:'row', height:44, paddingBottom:10, marginBottom:10, borderBottom:'solid 2px #DDD'}}>
          <div style={{position:'relative', height:44, marginRight:10}}>
            { !room.seen && <div style={{position:'absolute', top:'-5px', textAlign:'center', right:'-5px', backgroundColor:'#ef0707', fontSize:12, lineHeight:'20px', width:20, fontWeight:'bold', borderRadius:'50%', height:20, color:'#FFF'}}>1</div> }
            <div style={{height:44, fontFamily:'Poppins-Bold', width:44, borderRadius:'50%', textAlign:'center', color:'#FFF', fontSize:20, lineHeight:'44px', fontWeight:'bold', backgroundColor:'#999'}}>2</div>
          </div>
          <div style={{height:44, width:'calc(100% - 54px)'}}>
            <div style={{fontSize:16, whiteSpace:'nowrap', overflow:'hidden', textOverflow:'ellipsis', fontFamily:'Poppins-Bold', lineHeight:'18px', paddingTop:4, height:18, letterSpacing:-1}}>{room.lib}</div>
            <div style={{fontSize:14, whiteSpace:'nowrap', overflow:'hidden', textOverflow:'ellipsis', lineHeight:'18px', color:'#666', height:18, letterSpacing:-0.5}}>{getFormattedDate(room.lastMessageTS._seconds)} - {room.lastMessageTxt}</div>
          </div>
        </div>
      );
    });

    return rooms;

  }

  render = () => {

    const body = (
      <Container maxWidth="sm" style={{marginTop:25, marginBottom:65}}>
        { this.state.loading
          ? <div style={{textAlign:"center", marginTop:50}}><CircularProgress style={{color:"red"}} /></div>
          : this.state.rooms.length > 0 ?
            this.renderConversations() : 
              <div style={{textAlign:'center', color:'#777', lineHeight:'24px', fontSize:22, textTransform:'uppercase', fontFamily:'Poppins-Bold', marginTop:50}}>aucune conversation trouvée</div>
        }
      </Container>
    );

    return (<MainLayout body={body} />);

	}

}

const styles = {};

const App = ( props ) => {
  return <Messenger {...props} navigation={useNavigate()} />;
}

export default withStyles({...globalStyle.style,...styles})( App );
