import React, { Component } from 'react';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Close } from '@mui/icons-material';
import globalStyle from '../config/style';
import withStyles from '@material-ui/core/styles/withStyles';
import { useNavigate } from 'react-router';
import appConfig from '../config/application.js';
import { ChevronRight as ChevronRightIcon } from '@mui/icons-material';
import { setStateAsync, sortDictionnaryByKey } from '../utils/common';
import { fetchGetURLWithCache } from '../utils/cache';
import config from '../config/conf.js';
import { fetchPostURL, fetchGetURL } from '../utils/fetch';
import { clearCache } from '../utils/cache';
import GoogleMapPicker from './google-map-picker';

class AddSport extends Component {

  constructor (props) {

    super(props);

    this.locationRef =  React.createRef();
    this.locationButtonRef =  React.createRef();
    this.disponibilitiesRef =  React.createRef();
    this.tagsRef =  React.createRef();

    this.defaultLat = 47.057762;
    this.defaultLng = 2.788710;

    this.state = {
      selectedSport: null,
      locationButtonLoading: false,
      userLocation: {
        lat: this.defaultLat,
        long: this.defaultLng,
        country: ''
      },
      filter: {
        day: null,
        period: null
      },
      disponibilities: {
        'mon': [],
        'tue': [],
        'wed': [],
        'thu': [],
        'fri': [],
        'sat': [],
        'sun': []
      },
      allTags: [],
      displayedTags: [],
      selectedTags: {},
      errors: {},
      currentSports: [],
      loading: true,
      saveLoading: false,
      sportLoading: null,
      showTagSection: false
    };

  }

  componentDidMount = async () => {

    const response = await fetchGetURLWithCache('userGroups', `${config.api.base_url}${config.api.end_point.userGroups}`, {}, {}, true);
    await setStateAsync({currentSports: response.sports}, this);

    if (this.props.sport)
      await this.selectSport(this.props.sport);

    await setStateAsync({loading: false}, this);

  }

  selectSport = async (sport) => {

    await setStateAsync({sportLoading: sport}, this);

    let tags = [];
    const httpResponse = await fetchGetURLWithCache('configSportsTags', `${config.api.base_url}${config.api.end_point.configSportsTags}`, {}, {}, false);

    if (httpResponse[sport])
      tags = httpResponse[sport];

    await setStateAsync({
      selectedSport: sport,
      filter: {
        day: null,
        period: null
      },
      allTags: this.addDisplayedProperty(tags),
      displayedTags: [],
      disponibilities: {
        'mon': [],
        'tue': [],
        'wed': [],
        'thu': [],
        'fri': [],
        'sat': [],
        'sun': []
      },
      errors: {},
      selectedTags: {},
      sportLoading: null
    }, this);

    // PREFILL THE USER SETTINGS

    const userSportSettings = await fetchGetURL(`${config.api.base_url}${config.api.end_point.userSportSettings}`, {}, {sport});

    if (userSportSettings.location)
      await setStateAsync({userLocation: userSportSettings.location}, this);

    if (userSportSettings.disponibilities) 
      await setStateAsync({showTagSection: true, disponibilities: {...this.state.disponibilities, ...userSportSettings.disponibilities}}, this);

    if (userSportSettings.tags)
      await setStateAsync({selectedTags: userSportSettings.tags}, this);

    // Hide the tags with country, they will be displayed when the user refresh his location

    this.refreshTagByCountry();

  }

  addDisplayedProperty = (tags) => {

    for (const [key, tag] of Object.entries(tags)) {
      if (tag.conditions)
        tags[key] = {...tag, displayed: false};
      else
        tags[key] = {...tag, displayed: true};
    }

    return tags;

  }

  refreshUserLocation = async () => {

    this.setState({locationButtonLoading: true});

    navigator.geolocation.getCurrentPosition( async (position) => {
      await this.handleUserLocation(position.coords.latitude, position.coords.longitude);
      this.setState( { locationButtonLoading: false } );
    });

  }

  handleUserLocation = async (lat, long) => {

    let country = '';

    lat = Math.round(lat * 100000) / 100000;
    long = Math.round(long * 100000) / 100000;

    // get country (FR, GB, BE...) from lat and lng

    const payload = {
      key: config.apiKeys.opencagedata,
      q: `${lat}+${long}`
    };

    const geoLocData = await fetchGetURL(`${config.api.geoloc_api}${config.api.end_point.getCountryFromLoc}`, payload, {});
    
    if (geoLocData.results && geoLocData.results[0] && geoLocData.results[0].components && geoLocData.results[0].components['country_code'])
      country = geoLocData.results[0].components['country_code'].toUpperCase();

    await setStateAsync( { userLocation: { lat, long, country } }, this );

    this.refreshTagByCountry();
    this.scrollTo('locationButtonRef');

  }

  selectFilterDay = async (day) => {

    if (day === this.state.filter.day)
      await setStateAsync( { filter: {day: null, period: null} }, this );
    else
      await setStateAsync( { filter: {day, period: this.state.filter.period} }, this );

  }

  selectFilterPeriod = async (period) => {

    const selectedDay = this.state.filter.day;
    const disponibilities = this.state.disponibilities;

    if (disponibilities[selectedDay].indexOf(period) === -1)
      disponibilities[selectedDay].push(period);
    else
      disponibilities[selectedDay].splice( disponibilities[selectedDay].indexOf(period) , 1);

    await setStateAsync({disponibilities}, this);

    this.scrollTo('disponibilitiesRef');
    
  };

  dayBackgroundColor = (day) => {

    if (this.state.disponibilities[day].length > 0)
      return '#d1edb7';

    return '#EEE';

  }

  periodIsSelected = (period) => {

    if (!this.state.filter.day)
      return '#EEE';

    if (this.state.disponibilities[ this.state.filter.day ].indexOf(period.ref) !== -1)
      return '#d1edb7';

    return '#EEE';

  }

  renderPeriodIndicator = (day) => {

    const disponibilities = this.state.disponibilities;

    return (
      <div style={{position:'absolute', display:'flex', marginLeft:'auto', left:0, right:0, marginRight:'auto', justifyContent:'space-between', flexDirection:'row', bottom:5, width:20}}>
        { disponibilities[day].indexOf('mor') !== -1 ?
          <div style={{width:5, height:5, backgroundColor:'#000'}}></div> :
          <div style={{width:5, height:5, backgroundColor:'#BBB'}}></div>
        }
        { disponibilities[day].indexOf('aft') !== -1 ?
          <div style={{width:5, height:5, backgroundColor:'#000'}}></div> :
          <div style={{width:5, height:5, backgroundColor:'#BBB'}}></div>
        }
        { disponibilities[day].indexOf('eve') !== -1 ?
          <div style={{width:5, height:5, backgroundColor:'#000'}}></div> :
          <div style={{width:5, height:5, backgroundColor:'#BBB'}}></div>
        }
      </div>
    );

  }

  dayHighlighted = (day) => {

    if (this.state.filter.day === day.ref)
      return '0px 0px 7px 0px #999';

    return 'none';

  }

  invitationBoxTags = (tag) => {

    const tagsArray = [];

    const category = tag.id;
    const multi = tag.multi;
    const tags = sortDictionnaryByKey(tag.tags);

    for (const [key, tag] of Object.entries(tags)) {
      tagsArray.push(
        <div key={key} onClick={() => this.selectTag(category, key, multi)} style={{...this.getSelectedTags(category, key), color:'#fff', display:'inline-block', fontSize:12, lineHeight:'14px', fontWeight:'bold', borderRadius:10, padding:7, paddingLeft:12, paddingRight:12, margin:3}}>
          {tag}
        </div>
      );
    };

    return (
      <div style={{textAlign:'center', borderRadius:10, marginTop:15, marginBottom:30, padding:10, backgroundColor:'#eee'}}>
        { tagsArray }
        { this.state.errors[category] && <div style={{fontFamily:'Poppins-Bold', letterSpacing:-0.5, paddingTop:10, color:'red', fontSize:13, lineHeight:'14px'}}>merci de sélectionner au moins un tag</div> }
      </div>
    );

  }

  refreshTagByCountry = () => {

    const country = this.state.userLocation.country;

    if (!country)
      return null;

    const tags = this.state.allTags;

    const filteredTags = tags.filter(tag => !tag.country || tag.country.includes(country));

    this.setState({displayedTags: filteredTags});

  }

  refreshConditionTags = () => {

    const displayedTags = this.state.displayedTags;

    displayedTags.forEach((tag, tagRef) => {

      if (tag.conditions) {

        let checkConditionsBool = true;

        tag.conditions.forEach((condition) => {

          const conditionArray = condition.split(':');
          const tag = conditionArray[0];
          const property = conditionArray[1];

          if (!this.checkCondition(tag, property))
            checkConditionsBool = false;

        });

        displayedTags[tagRef].displayed = checkConditionsBool;
        
      }

    });

    this.setState({displayedTags});

  }

  checkCondition = (tag, property) => {

    const selectedTags = this.state.selectedTags;
    let output = false;

    for (const [key, tags] of Object.entries(selectedTags)) {
      if (key == tag && tags == property)
        output = true;
    };

    return output;

  }

  selectTag = (category, tag, multi) => {

    const selectedTags = this.state.selectedTags;

    if (!selectedTags[category])
      selectedTags[category] = [];

    if ( selectedTags[category].indexOf(tag) !== -1 ) {
      selectedTags[category].splice( selectedTags[category].indexOf(tag), 1);
    } else if (multi) {
      selectedTags[category].push(tag);
    } else {
      selectedTags[category] = [];
      selectedTags[category].push(tag);
    }

    if (selectedTags[category].length === 0)
      delete selectedTags[category];

    this.refreshConditionTags();

    this.setState({selectedTags});

  }

  getSelectedTags = (category, tag) => {

    const selectedTags = this.state.selectedTags;

    if (!selectedTags[category])
      selectedTags[category] = [];

    if ( selectedTags[category].indexOf(tag) !== -1 )
      return {backgroundColor:'#EE0A4C'};
    else
      return {backgroundColor:'#000'};

  }

  shortenerDisponibilities = (disponibilities) => {

    const output = {};

    for (const [key, day] of Object.entries(disponibilities)) {
      if (day.length > 0)
        output[key] = day
    }

    return output;

  }

  scrollTo = (location) => {
    this[location].current.scrollIntoView();
  }

  checkDisponibilities = () => {

    // return true if disponibilites are completed

    let output = false;

    for (const [key, dispo] of Object.entries(this.state.disponibilities)) {
      if (dispo.length > 0)
        output = true;
    }

    return output;

  }

  save = async () => {

    await setStateAsync({saveLoading: true}, this);

    const tagsPattern = this.state.displayedTags;

    const disponibilities = this.shortenerDisponibilities(this.state.disponibilities);
    const location = this.state.userLocation;
    const selectedTags = this.state.selectedTags;
    const errors = {};

    // CHECK LOCATION

    if (!location.lat || !location.long)
      errors['location'] = true;

    if (location.lat === this.defaultLat && location.long === this.defaultLng)
      errors['location'] = true;

    // CHECK DISPONIBILITIES

    if (!this.checkDisponibilities())
      errors['disponibilities'] = true;

    // CHECK TAGS

    tagsPattern.forEach((tag) => {

      if (tag.mandatory) {

        errors[tag.id] = true;

        // THIS TAG IS MANDATORY

        if ( selectedTags[tag.id] && selectedTags[tag.id].length > 0 )
          delete errors[tag.id];

      }

    });

    // REMOVE EMPTY TAGS

    const payloadTags = {};

    for (const [key, tags] of Object.entries(selectedTags)) {
      const tagIsDisplayed = this.state.displayedTags.filter(tag => tag.id === key);
      if (tagIsDisplayed[0] && tagIsDisplayed[0].displayed && tags.length > 0)
        payloadTags[key] = tags;
    }

    await setStateAsync({errors}, this);

    if ( Object.entries(errors).length > 0 ) {

      if (errors['location'])
        this.scrollTo('locationRef');
      else if (errors['disponibilities'])
        this.scrollTo('disponibilitiesRef');
      else
        this.scrollTo('tagsRef');

      await setStateAsync({saveLoading: false}, this);

      return false;

    }

    // API

    const payload = {
      sport: this.state.selectedSport,
      location: JSON.stringify(location),
      disponibilities: JSON.stringify(disponibilities),
      tags: JSON.stringify(payloadTags)
    }

    await fetchPostURL(`${config.api.base_url}${config.api.end_point.addSport}`, payload, {});
    await clearCache('userGroups');
    await setStateAsync({saveLoading: false}, this);

    window.location.reload();

  }

  showTagSection = async () => {

    await setStateAsync({showTagSection: true}, this);
    this.scrollTo('disponibilitiesRef');

  }

  render = () => {

    const { classes } = this.props;

    const services = appConfig.services;
    const availableSports = appConfig.sports;
    const sports = [];

    // GET CURRENT CONFIGURATED USER SPORTS

    services.forEach((service) => {

      const thisSport = availableSports[service.sport];

      if (!this.state.currentSports.includes(service.sport)) {

        sports.push(
          <div key={service.sport} onClick={() => this.selectSport(service.sport)} >
            <div style={{display:'flex', justifyContent: 'space-between', margin:5, padding:5, borderRadius:10, backgroundColor:'#EEE'}}>
              <div style={{display:'flex'}}>
                <div style={{margin:3, position:'relative', height:60, width:60, borderRadius:10, backgroundRepeat:'no-repeat', backgroundPosition:'50% 50%', background:`linear-gradient( rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35) ), url(${thisSport.img})`, backgroundSize:'cover'}}></div>
                <div style={{display:'flex', alignItems:'center'}}>
                  <div style={{fontFamily:'Poppins-Bold', fontSize:18, lineHeight:'20px', marginLeft:7}}>{thisSport.text['fr']}</div>
                </div>
              </div>
              <div style={{display:'flex', alignItems:'center', paddingRight:7}}>
                { this.state.sportLoading === service.sport ? <CircularProgress style={{color:"#999"}} size={28} /> : <ChevronRightIcon style={{color:'#999', fontSize:42}} /> }
              </div>
            </div>
          </div>
        );

      }

    });

    let body;

    if (!this.state.selectedSport) {

      body = (
        <Container maxWidth="sm" style={{padding:0}}>
  
          <div style={{position:'absolute', top:10, left:10}} onClick={ () => this.props.setModalStatus(false) }><Close style={{fontSize:32}} /></div>
  
          <div style={{fontFamily:'Poppins-Bold', textAlign:'center', marginBottom:5, textTransform:'uppercase', color:'#000', fontWeight:'bold', lineHeight:'22px', fontSize:22, letterSpacing:-1}}>
            ajouter un sport
          </div>
  
          { sports.length > 0 && <div style={{textAlign:'center', marginTop:15, marginBottom:15, textTransform:'uppercase', color:'#666', fontWeight:'bold', lineHeight:'18px', fontSize:16}}>
            configurez vos sports favoris pour rencontrer de nouvelles personnes à proximité et enrichir votre pratique sportive
          </div> }
  
          <div>
            { sports.length > 0 ? sports : <div style={{textAlign:'center', marginTop:15, marginBottom:15, textTransform:'uppercase', color:'#666', fontWeight:'bold', lineHeight:'18px', fontSize:16}}>vous avez configuré tous les sports disponibles</div> }
          </div>
  
        </Container>
      );

    } else {

      const thisSport = availableSports[this.state.selectedSport];

      const showDisponibilitiesSection = ( this.state.userLocation.lat !== this.defaultLat && this.state.userLocation.long !== this.defaultLng );
      const showTagsSection = ( showDisponibilitiesSection && this.checkDisponibilities() && this.state.displayedTags.length > 0 );

      body = (
        <Container maxWidth="sm" style={{padding:0, paddingTop:25}}>

          <div style={{position:'absolute', top:10, left:10}} onClick={ () => this.props.setModalStatus(false) }><Close style={{fontSize:32}} /></div>

          { !this.props.sport &&
            <div key={this.state.selectedSport} onClick={() => this.selectSport(null)} >
              <div style={{display:'flex', justifyContent: 'space-between', margin:5, padding:5, marginBottom:20, borderRadius:10, backgroundColor:'#EEE'}}>
                <div style={{display:'flex'}}>
                  <div style={{margin:3, position:'relative', height:50, width:50, borderRadius:7, backgroundRepeat:'no-repeat', backgroundPosition:'50% 50%', background:`linear-gradient( rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35) ), url(${thisSport.img})`, backgroundSize:'cover'}}></div>
                  <div style={{display:'flex', alignItems:'center'}}>
                    <div style={{fontFamily:'Poppins-Bold', fontSize:16, lineHeight:'20px', marginLeft:7}}>{thisSport.text['fr']}</div>
                  </div>
                </div>
                <div style={{display:'flex', alignItems:'center'}}>
                  <Close style={{color:'#999', fontSize:32}} />
                </div>
              </div>
            </div> }

          <div ref={this.locationRef} style={{fontFamily:'Poppins-Bold', textAlign:'center', paddingTop:15, marginBottom:5, textTransform:'uppercase', color:'#000', fontWeight:'bold', lineHeight:'20px', fontSize:20, letterSpacing:-1}}>
            1/3 - votre position
          </div>
          <div style={{textAlign:'center', marginTop:10, marginBottom:15, textTransform:'uppercase', color:'#999', fontWeight:'bold', lineHeight:'17px', fontSize:15}}>
            définissez votre position pour être contacté par des personnes à proximité. votre position exact ne sera pas partagée avec les autres utilisateurs.
          </div>
          <div ref={this.locationButtonRef}>
            <Button
              className={classes.geoButton}
              type="submit"
              fullWidth
              variant="contained"
              onClick={() => this.refreshUserLocation()}
              disabled={this.state.locationButtonLoading}
            >
              { this.state.locationButtonLoading ?
                <CircularProgress style={{color:"#FFF"}} size={20} /> : "me localiser" }
            </Button>
          </div>
          <div style={{textAlign:'center', marginTop:7, lineHeight:'12px', fontSize:12}}>vous pouvez placer le marker sur votre position approximative</div>
          <GoogleMapPicker
            location={{lat: this.state.userLocation.lat, lng: this.state.userLocation.long}}
            callback={this.handleUserLocation}
          />

          { this.state.errors.location && <div style={{fontFamily:'Poppins-Bold', letterSpacing:-0.5, paddingLeft:10, paddingRight:10, color:'red', fontSize:13, lineHeight:'14px', textAlign:'center', marginTop:10}}>votre position n'est pas à jour</div> }

            { showDisponibilitiesSection && <div>
            
              <div ref={this.disponibilitiesRef} style={{fontFamily:'Poppins-Bold', textAlign:'center', marginTop:20, paddingTop:15, marginBottom:5, textTransform:'uppercase', color:'#000', fontWeight:'bold', lineHeight:'20px', fontSize:20, letterSpacing:-1}}>
                2/3 - vos disponibilités
              </div>
              <div style={{textAlign:'center', marginTop:10, marginBottom:15, textTransform:'uppercase', color:'#999', fontWeight:'bold', lineHeight:'17px', fontSize:15}}>
                indiquez aux autres utilisateurs vos disponibilités favorites
              </div>
              <div style={{display:'flex', flexDirection:'column', borderRadius:10, padding:10, backgroundColor:'#fff'}}>
                    
                <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', height:40, lineHeight:'40px', marginBottom:10, fontWeight:'bold'}}>
                  { appConfig.days.map((day) => <div key={day.ref} onClick={() => this.selectFilterDay(day.ref)} style={{width:'14%', textAlign:'center', position:'relative', backgroundColor:this.dayBackgroundColor(day.ref), boxShadow:this.dayHighlighted(day) }}>{day.txt['fr']}{this.renderPeriodIndicator(day.ref)}</div> )}
                </div>
                {this.state.filter.day && <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', fontSize:13, height:30, lineHeight:'30px', fontWeight:'bold'}}>
                  { appConfig.periods.map((period) => <div key={period.ref} onClick={() => this.selectFilterPeriod(period.ref)} style={{width:'33%', textAlign:'center', backgroundColor:this.periodIsSelected(period)}}>{period.txt['fr']}</div> ) }
                </div>}
                
              </div>
            
            </div> }

          { this.state.errors.disponibilities && <div style={{fontFamily:'Poppins-Bold', letterSpacing:-0.5, paddingLeft:10, paddingRight:10, color:'red', fontSize:13, lineHeight:'14px', textAlign:'center'}}>sélectionnez vos disponibilités</div> }

          { showTagsSection && !this.state.showTagSection && <div>
              <Button
                style={{marginTop:20}}
                className={classes.commonButton}
                type="submit"
                fullWidth
                variant="contained"
                onClick={() => this.showTagSection()}
              >
                étape suivante
              </Button>
            </div>
          }

          { showTagsSection && this.state.showTagSection && <div>

              <div ref={this.tagsRef} style={{fontFamily:'Poppins-Bold', textAlign:'center', marginTop:20, paddingTop:15, marginBottom:5, textTransform:'uppercase', color:'#000', fontWeight:'bold', lineHeight:'20px', fontSize:20, letterSpacing:-1}}>
                3/3 - vos tags
              </div>
              <div style={{textAlign:'center', marginTop:10, marginBottom:15, textTransform:'uppercase', color:'#999', fontWeight:'bold', lineHeight:'17px', fontSize:15}}>
                choisissez les tags qui correspondent à votre profil
              </div>

              { this.state.displayedTags.map( (tag) => 
                  tag.displayed &&
                    <div key={tag.id}>
                      <div style={{textAlign:'center', marginBottom:5, textTransform:'uppercase', color:'#666', fontWeight:'bold', lineHeight:'16px', fontSize:16}}>{tag.name} { !tag.mandatory ? '(optionnel)' : '*' }</div>
                      { this.invitationBoxTags(tag) }
                    </div>
                )
              }

            </div>

          }

          { ( this.state.showTagSection && showDisponibilitiesSection && showTagsSection ) && <Button
            style={{marginTop:20}}
            className={classes.commonButton}
            type="submit"
            fullWidth
            variant="contained"
            onClick={() => this.save()}
            disabled={this.state.saveLoading}
          >
            { this.state.saveLoading ?
              <CircularProgress style={{color:"#FFF"}} size={20} /> : "enregistrer"
            }
          </Button> }
        </Container>
      );
      
    }

    return (
      <Dialog
        open={this.props.showModal}
        className={classes.dialogue}
        onClose={() => { this.props.setModalStatus(false) }}
      >
        <DialogContent>
          { this.state.loading
            ? <div style={{textAlign:"center", marginTop:80, marginBottom:50, color:'#FFF'}}><CircularProgress style={{color:"red"}} /><br/> -----------------------------------------</div>
            : body }
        </DialogContent>
      </Dialog>
    );

  }

}

const styles = {
  geoButton: {
    backgroundColor:`#333!important`,
    fontSize:14,
    fontWeight:'bold',
    borderRadius:23,
    height:30,
    color:'#FFF'
  },
  commonButton: {
    backgroundColor:`${globalStyle.colors.primary}!important`,
    fontSize:14,
    fontWeight:'bold',
    borderRadius:23,
    height:30,
    color:'#ffffff'
  },
  loginButton: {
    backgroundColor:`${globalStyle.colors.primary}!important`,
    fontSize:16,
    marginTop:10,
    marginBottom:10,
    fontWeight:'bold',
    borderRadius:23,
    height:40,
    color:'#ffffff'
  }
};

const App = ( props ) => <AddSport {...props} navigation={useNavigate()} />;

export default withStyles({...globalStyle.style,...styles})( App );
