import React, { Component } from 'react';
import MainLayout from '../layout/main';
import withStyles from '@material-ui/core/styles/withStyles';
import Container from '@material-ui/core/Container';
import TextField from '@mui/material/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import UserBadge from '../component/user-badge';
import { useNavigate } from 'react-router';
import { useParams } from "react-router-dom";
import globalStyle from '../config/style';
import config from '../config/conf.js';
import { fetchPostURL, fetchGetURL } from '../utils/fetch';
import { setStateAsync, getFormattedDate } from '../utils/common';

class Room extends Component {

  constructor (props) {
    super(props);
    this.state = {
      loading: true,
      newMessage: '',
      newMessageLabel: 'nouveau message',
      newMessageDisabled: false,
      user: {},
      users: [],
      messages: []
    };
  }
  
  scrollToBottom = () => {
    this.messagesEnd.scrollIntoView();
  }
  
  componentDidMount = async () => {

    this.refreshMessage();
  }
  
  componentDidUpdate = () => {
    this.scrollToBottom();
  }

  postNewMessage = async () => {

    const newMessage = this.state.newMessage;
    await setStateAsync({newMessageDisabled: true}, this);

    await fetchPostURL(
      `${config.api.base_url}${config.api.end_point.newMessage}`,
      {message: newMessage},
      {roomId: this.props.params.roomId}
    );

    await this.refreshMessage();
    await setStateAsync({newMessageDisabled: false, newMessage: '', newMessageLabel: 'nouveau message'}, this);
    
  }

  handleNewMessage = (event) => {

    if (event.nativeEvent.inputType === 'insertLineBreak') {

      this.postNewMessage();

    } else {

      this.setState({
        'newMessage': `${event.target.value}`
      });

    }

  };

  refreshMessage = async () => {

    const response = await fetchGetURL(`${config.api.base_url}${config.api.end_point.room}`, {}, {roomId: this.props.params.roomId}, true);

    const messages = response.messages;
    const users = response.users;
    const header = response.header;
    const uid = localStorage.getItem('UserId');

    messages.forEach((message, k) => {
      if (uid === message.from)
        messages[k].from = null;
      else
        messages[k].from = {...users[message.from], id: message.from};
    });

    await setStateAsync({
      'messages': messages,
      'loading': false,
      'header': {
        user: header.user
      }
    }, this);

    this.scrollToBottom();
    
  }

  renderMessages = () => {

    const output = [];

    this.state.messages.forEach((message) => {

      if (message.from)
        output.push(
          <div key={message.id} style={{display:'flex', flexDirection:'row', marginTop:15}}>
            <div style={{display:'flex', flexDirection:'column', width:'80%'}}>
              <div style={{fontSize:13, paddingLeft:20, color:'#666'}}><span style={{fontFamily:'Poppins-Bold'}}>{ message.from.firstname } { message.from.lastname }</span> - { getFormattedDate(message.ts._seconds) }</div>
              <div style={{backgroundColor:'#FFF', padding:15, fontSize:14, borderRadius:22, border:'solid 1px #e1e1e1', width:'fit-content'}}>{ message.text }</div>
            </div>
          </div>
        );
      else
        output.push(
          <div key={message.id} style={{display:'flex', flexDirection:'row', justifyContent:'flex-end', marginTop:15}}>
            <div style={{display:'flex', flexDirection:'column', width:'80%', justifyContent:'flex-end'}}>
              <div style={{fontSize:12, textAlign:'right', paddingRight:20, color:'#666'}}>{ getFormattedDate(message.ts._seconds) }</div>
              <div style={{display:'flex', justifyContent:'flex-end'}}>
                <div style={{padding:15,fontSize:14, borderRadius:22, backgroundColor:'#e1e1e1', width:'fit-content'}}>{ message.text }</div>
              </div>
            </div>
          </div>
        );

    });

    return output;

  }

  render = () => {

    const { classes } = this.props;

    const body = (
      <Container maxWidth="sm" style={{marginTop:25, marginBottom:35}}>
        
        { !this.state.loading
          ? <div>
              { this.state.header.user && <UserBadge badge={this.state.header.user} /> }
              { this.renderMessages() }
            </div>
          : <div style={{textAlign:"center", marginTop:50}}>
              <CircularProgress style={{color:"red"}} />
            </div>
        }

        <div id={'scrollMarker'} ref={(el) => { this.messagesEnd = el; }}></div>

        <div style={{position:'fixed', width:'90%', backgroundColor:'#FFF', transform:'translate(-50%, 0)', bottom:10, left:'50%'}}>
          <TextField
            name="newMessage"
            fullWidth
            variant="outlined"
            label={this.state.newMessageLabel}
            multiline
            className={classes.commonTextField}
            style={{width:'100%'}}
            maxRows={3}
            disabled={this.state.newMessageDisabled}
            value={this.state.newMessage}
            onChange={this.handleNewMessage}
          />  
        </div>

      </Container>
    );

    return (<MainLayout hideFooter back={'/messenger'} body={body} />);

	}

}

const styles = {};

const App = ( props ) => <Room {...props} params={useParams()} navigation={useNavigate()} />

export default withStyles({...globalStyle.style,...styles})( App );
