import React, { Component } from 'react';
import MainLayout from '../layout/main';
import withStyles from '@material-ui/core/styles/withStyles';
import Container from '@material-ui/core/Container';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import globalStyle from '../config/style';

class CreateGroup extends Component {

  constructor (props) {
    super(props);
    this.state = {
      showActivityDetails: false
    };
  }

  componentDidMount = async () => {
    document.title = "Happy Sports - Créer un club";
  }

  render = () => {

    const { classes } = this.props;

    const top100Films = [
      { name: 'Tennis', id: 'tennis' },
      { name: 'Padel-Tennis', id: 'padel-tennis' },
      { name: 'Voile', id: 'voile' },
      { name: 'Kayak', id: 'kayak' },
    ];

    const body = (
      <Container maxWidth="sm" style={{marginTop:25, marginBottom:65}}>
        
        <TextField
          variant="outlined"
          className={classes.commonTextField}
          fullWidth
          id="password"
          label="Nom du club"
          name="password"
          style={{marginTop:25, marginBottom:25}}
        />

        <Autocomplete
          multiple
          className={classes.commonAutoComplete}
          options={top100Films}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => (
            <TextField {...params} label="Sports" />
          )}
        />

        <Button
          className={classes.button}
          type="submit"
          fullWidth
          variant="contained"
          onClick={() => {}}
        >
          Créer
        </Button>
        
      </Container>
    );

    return (<MainLayout body={body} />);

	}

}

const styles = {
  buttonContact: {
    backgroundColor:globalStyle.colors.primary,
    fontSize:14,
    fontWeight:'bold',
    borderRadius:10,
    height:35,
    color:'#ffffff',
    marginTop:10
  },
  button: {
    backgroundColor:globalStyle.colors.primary,
    fontSize:16,
    fontWeight:'bold',
    borderRadius:23,
    height:45,
    color:'#ffffff',
    marginTop:35
  }
};

export default withStyles({...globalStyle.style,...styles})(CreateGroup);
