import React, { Component } from 'react';
import MainLayout from '../layout/main';
import withStyles from '@material-ui/core/styles/withStyles';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import TextField from '@material-ui/core/TextField';
import globalStyle from '../config/style';
import padelBg from '../asset/image/sports/padel.jpeg';
import tennisBg from '../asset/image/sports/tennis.jpeg';
import voileBg from '../asset/image/sports/voile.jpeg';
import kayakBg from '../asset/image/sports/kayak.jpeg';

class Home extends Component {

  constructor (props) {
    super(props);
    this.state = {
      joinGroupModal: false,
      activityDetailsModal: false
    };
  }

  componentDidMount = async () => {
    document.title = "Happy Sports - Home";
  }

  renderActivityDetails = () => {

    const { classes } = this.props;

    return (
      <div style={{display:'flex', flexDirection:'column'}}>
        <div style={{display:'flex', flexDirection:'row'}}>
          <div style={{width:65, textAlign:'center', display:'flex', flexDirection:'column', borderRight:'solid 1px', borderRightColor:globalStyle.colors.primary, marginRight:15}}>
            <div style={{fontSize:16, lineHeight:'18px', color:'#777', letterSpacing:2}}>Jeu</div>
            <div style={{fontWeight:'bolder', fontSize:28, lineHeight:'32px', fontFamily:'Poppins-Bold'}}>20</div>
            <div style={{fontSize:16, lineHeight:'18px', color:'#777', letterSpacing:2}}>juil</div>
          </div>
          <div style={{display:'flex', flexDirection:'column'}}>
            <div style={{fontFamily:'Poppins-Bold', lineHeight:'24px', fontSize:22}}>Cours Collectif</div>
            <div style={{fontSize:16, lineHeight:'22px', color:'#666', letterSpacing:1}}>14:00</div>
            <div style={{fontSize:16, lineHeight:'22px', fontWeight:'bolder', letterSpacing:2}}>15€</div>
          </div>
        </div>
        <div style={{marginTop:20, fontSize:18, lineHeight:'20px'}}>
          cours collectif, 3 élèves, il manque 1 joueur, on travaille la volée
        </div>
        <div style={{marginTop:20, marginBottom:15}}>
          <div style={{display:'inline-block', fontSize:12, lineHeight:'13px', fontWeight:'bold', backgroundColor:'#000', color:'#fff', borderRadius:10, padding:5, paddingLeft:10, paddingRight:10, margin:3}}>ASSM Saint Luc</div>
          <div style={{display:'inline-block', fontSize:12, lineHeight:'13px', fontWeight:'bold', backgroundColor:'#000', color:'#fff', borderRadius:10, padding:5, paddingLeft:10, paddingRight:10, margin:3}}>Tennis</div>
          <div style={{display:'inline-block', fontSize:12, lineHeight:'13px', fontWeight:'bold', backgroundColor:globalStyle.colors.gray, color:'#fff', borderRadius:10, padding:5, paddingLeft:10, paddingRight:10, margin:3}}>adulte (18+)</div>
          <div style={{display:'inline-block', fontSize:12, lineHeight:'13px', fontWeight:'bold', backgroundColor:globalStyle.colors.gray, color:'#fff', borderRadius:10, padding:5, paddingLeft:10, paddingRight:10, margin:3}}>niveau intermédiaire</div>
          <div style={{display:'inline-block', fontSize:12, lineHeight:'13px', fontWeight:'bold', backgroundColor:globalStyle.colors.gray, color:'#fff', borderRadius:10, padding:5, paddingLeft:10, paddingRight:10, margin:3}}>durée: 1h30</div>
          <div style={{display:'inline-block', fontSize:12, lineHeight:'13px', fontWeight:'bold', backgroundColor:globalStyle.colors.gray, color:'#fff', borderRadius:10, padding:5, paddingLeft:10, paddingRight:10, margin:3}}>1 place disponible</div>
          <div style={{display:'inline-block', fontSize:12, lineHeight:'13px', fontWeight:'bold', backgroundColor:globalStyle.colors.gray, color:'#fff', borderRadius:10, padding:5, paddingLeft:10, paddingRight:10, margin:3}}>proposé par Alain</div>
        </div>
        <div>
          <Button
            className={classes.askToJoinButton}
            type="submit"
            fullWidth
            variant="contained"
            onClick={() => {}}
          >
            Demander à participer
          </Button>
          <Button
            className={classes.askToJoinButton}
            type="submit"
            fullWidth
            variant="contained"
            onClick={() => {}}
          >
            Contacter Alain
          </Button>
        </div>
      </div>
    );

  }

  renderActivity = () => {

    return (
      <div onClick={() => { this.setState({activityDetailsModal: true}) }} style={{display:'flex', flexDirection:'column', borderRadius:'15px', cursor:'pointer', boxShadow:'5px 10px 20px 3px #DDD', backgroundColor:'#fff', padding:15, marginBottom:25}}>
        <div style={{display:'flex', flexDirection:'row'}}>
          <div style={{width:65, textAlign:'center', display:'flex', flexDirection:'column', borderRight:'solid 1px', borderRightColor:globalStyle.colors.primary, marginRight:15}}>
            <div style={{fontSize:16, lineHeight:'18px', color:'#777', letterSpacing:2}}>Jeu</div>
            <div style={{fontWeight:'bolder', fontSize:28, lineHeight:'32px', fontFamily:'Poppins-Bold'}}>20</div>
            <div style={{fontSize:16, lineHeight:'18px', color:'#777', letterSpacing:2}}>Juin</div>
          </div>
          <div style={{display:'flex', flexDirection:'column'}}>
            <div style={{fontFamily:'Poppins-Bold', lineHeight:'24px', fontSize:20}}>Cours Collectif</div>
            <div style={{fontSize:16, lineHeight:'22px', color:'#666', letterSpacing:1}}>14:00</div>
            <div style={{fontSize:16, lineHeight:'22px', fontWeight:'bolder', letterSpacing:2}}>15€</div>
          </div>
        </div>
        <div style={{marginTop:10}}>
          <div style={{display:'inline-block', fontSize:12, lineHeight:'13px', fontWeight:'bold', backgroundColor:'#000', color:'#fff', borderRadius:10, padding:5, paddingLeft:10, paddingRight:10, margin:3}}>ASSM Saint Luc</div>
          <div style={{display:'inline-block', fontSize:12, lineHeight:'13px', fontWeight:'bold', backgroundColor:'#000', color:'#fff', borderRadius:10, padding:5, paddingLeft:10, paddingRight:10, margin:3}}>Tennis</div>
          <div style={{display:'inline-block', fontSize:12, lineHeight:'13px', fontWeight:'bold', backgroundColor:globalStyle.colors.gray, color:'#fff', borderRadius:10, padding:5, paddingLeft:10, paddingRight:10, margin:3}}>adulte (18+)</div>
          <div style={{display:'inline-block', fontSize:12, lineHeight:'13px', fontWeight:'bold', backgroundColor:globalStyle.colors.gray, color:'#fff', borderRadius:10, padding:5, paddingLeft:10, paddingRight:10, margin:3}}>niveau intermédiaire</div>
          <div style={{display:'inline-block', fontSize:12, lineHeight:'13px', fontWeight:'bold', backgroundColor:globalStyle.colors.gray, color:'#fff', borderRadius:10, padding:5, paddingLeft:10, paddingRight:10, margin:3}}>durée: 1h30</div>
          <div style={{display:'inline-block', fontSize:12, lineHeight:'13px', fontWeight:'bold', backgroundColor:globalStyle.colors.gray, color:'#fff', borderRadius:10, padding:5, paddingLeft:10, paddingRight:10, margin:3}}>1 place disponible</div>
          <div style={{display:'inline-block', fontSize:12, lineHeight:'13px', fontWeight:'bold', backgroundColor:globalStyle.colors.gray, color:'#fff', borderRadius:10, padding:5, paddingLeft:10, paddingRight:10, margin:3}}>proposé par Alain</div>
        </div>
        <div style={{marginTop:10, fontSize:14, textAlign:'right'}}>
          en savoir plus
        </div>
      </div>
    );

  }

  renderAskedActivity = () => {

    return (
      <div onClick={() => { this.setState({activityDetailsModal: true}) }} style={{display:'flex', flexDirection:'column', borderRadius:'15px', cursor:'pointer', boxShadow:'5px 10px 20px 3px #DDD', backgroundColor:'#fff', padding:15, marginBottom:25}}>
        <div style={{display:'flex', flexDirection:'row'}}>
          <div style={{width:65, textAlign:'center', display:'flex', flexDirection:'column', borderRight:'solid 1px #428892', marginRight:15}}>
            <div style={{fontSize:16, lineHeight:'18px', color:'#777', letterSpacing:2}}>Jeu</div>
            <div style={{fontWeight:'bolder', fontSize:28, lineHeight:'32px', fontFamily:'Poppins-Bold'}}>20</div>
            <div style={{fontSize:16, lineHeight:'18px', color:'#777', letterSpacing:2}}>Juin</div>
          </div>
          <div style={{display:'flex', flexDirection:'column'}}>
            <div style={{fontFamily:'Poppins-Bold', lineHeight:'24px', fontSize:20}}>Cours Collectif</div>
            <div style={{fontSize:16, lineHeight:'22px', color:'#666', letterSpacing:1}}>14:00</div>
            <div style={{fontSize:16, lineHeight:'22px', fontWeight:'bolder', letterSpacing:2}}>15€</div>
          </div>
        </div>
        <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', marginTop:10, fontSize:14, textAlign:'right'}}>
          <div style={{fontWeight:'bold', color:'orange'}}>en attente de confirmation</div>
          <div>en savoir plus</div>
        </div>
      </div>
    );

  }

  renderSportChoice = (name, img) => {

    return (
      <div style={{margin:3, boxShadow:'5px 5px 10px 2px #BBB', position:'relative', height:100, width:100, borderRadius:25, backgroundRepeat:'no-repeat', backgroundPosition:'50% 50%', background:`linear-gradient( rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35) ), url(${img})`, backgroundSize:'cover'}}>
        <div style={{fontFamily:'Poppins-Bold', fontSize:14, color:'#FFF', position:'absolute', bottom:"5px", left:"10px"}}>{name}</div>
      </div>
    );

  }

  render = () => {

    const { classes } = this.props;

    const body = (
      <Container maxWidth="sm" style={{marginTop:25, marginBottom:65}}>

        <Button
          className={classes.joinGroupButton}
          type="submit"
          fullWidth
          variant="contained"
          onClick={() => { this.setState({'joinGroupModal': true}) }}
        >
          Rejoindre un groupe
        </Button>

        <div style={{fontFamily:'Poppins-Bold', fontSize:22, marginBottom:10, paddingLeft:10}}>Mes prochaines activités</div>
        {/* { this.renderAskedActivity() } */}
        <div style={{fontSize:16, textAlign:'center', color:'#666', marginTop:20}}>vous n'êtes inscrit dans aucune activité</div>
        <div style={{fontFamily:'Poppins-Bold', fontSize:22, marginTop:65, marginBottom:10, paddingLeft:10}}>Activités disponibles</div>
        
        <div style={{display:'flex', overflowX:'auto', overflowY:'auto', marginBottom:15}}>
          <div style={{marginBottom:15, display:'flex', flexDirection:'row'}}>
            {this.renderSportChoice('Padel', padelBg)}
            {this.renderSportChoice('Tennis', tennisBg)}
            {this.renderSportChoice('Voile', voileBg)}
            {this.renderSportChoice('Kayak', kayakBg)}
          </div>
        </div>
        
        {this.renderActivity()}
        {this.renderActivity()}
        {this.renderActivity()}

        <SwipeableDrawer
          style={{borderRadius:20}}
          anchor={'bottom'}
          open={this.state.joinGroupModal}
          onClose={() => { this.setState({'joinGroupModal': false}) }}
          onOpen={() => {}}
        >
          <Container maxWidth="sm" style={{marginTop:20, marginBottom:20}}>
            <div style={{padding:10, textAlign:'center'}}>
              <div style={{marginBottom:20, fontWeight:'bold', fontSize:20, lineHeight:'22px'}}>entrez le code indiqué par votre club pour voir les activités disponibles</div>
              <TextField
                variant="outlined"
                fullWidth
                id="code"
                label="code (ex: YHG56T)"
                name="code"
              />
              <Button
                style={{marginTop:20}}
                className={classes.validJoinGroupButton}
                type="submit"
                fullWidth
                variant="contained"
                onClick={() => {}}
              >
                rejoindre
              </Button>
            </div>
          </Container>
        </SwipeableDrawer>
        <SwipeableDrawer
          style={{borderRadius:20}}
          anchor={'bottom'}
          open={this.state.activityDetailsModal}
          onClose={() => { this.setState({'activityDetailsModal': false}) }}
          onOpen={() => {}}
        >
          <Container maxWidth="sm" style={{marginTop:20, marginBottom:20}}>
            {this.renderActivityDetails()}
          </Container>
        </SwipeableDrawer>

      </Container>
    );

    return (<MainLayout body={body} />);

	}

}

const styles = {
  validJoinGroupButton: {
    backgroundColor:globalStyle.colors.primary,
    fontSize:16,
    fontWeight:'bold',
    borderRadius:23,
    height:45,
    color:'#ffffff'
  },
  joinGroupButton: {
    backgroundColor:globalStyle.colors.primary,
    fontSize:16,
    marginBottom:35,
    fontWeight:'bold',
    borderRadius:23,
    height:45,
    color:'#ffffff'
  },
  askToJoinButton: {
    backgroundColor:globalStyle.colors.primary,
    fontSize:16,
    marginTop:10,
    fontWeight:'bold',
    borderRadius:23,
    height:45,
    color:'#ffffff'
  }
}

export default withStyles({...globalStyle.style,...styles})(Home);
