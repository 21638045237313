import React, { Component } from 'react';
import LoginLayout from '../layout/login';
import withStyles from '@material-ui/core/styles/withStyles';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import globalStyle from '../config/style';
import { ChevronLeft } from '@mui/icons-material';
import { useNavigate } from 'react-router';
import { fetchPostURL, fetchGetURL } from '../utils/fetch';
import { setStateAsync, validateEmail } from '../utils/common';
import config from '../config/conf.js';

class CreateUser extends Component {

  constructor (props) {
    super(props);
    this.state = {
      firstname: '',
      lastname: '',
      email: '',
      password: '',
      loading: false,
      errors: {}
    };
  }

  componentDidMount = async () => {
    document.title = "S'inscrire";
    this.checkToken();
  }

  checkToken = async () => {

    const token = localStorage.getItem('AuthToken');
    const invitationCode = localStorage.getItem('invitationCode');
    let httpResponse;

    if (token) {

      httpResponse = await fetchGetURL(`${config.api.base_url}${config.api.end_point.checkToken}`, {});

      if (httpResponse === 'token_ok') {

        if (invitationCode) {
          localStorage.removeItem('invitationCode');
          this.props.navigation(`/join/${invitationCode}`);
        } else {
          this.props.navigation('/subscribes');
        }

      }
      
    }

  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  createUser = async () => {

    await setStateAsync({loading: true}, this);

    let check = true;
    const errorMessage = {};
    const fields = ['firstname', 'lastname', 'email', 'password'];

    for (const field of fields) {

      if (this.state[field] === '') {
        errorMessage[field] = 'champ requis';
        check = false;
      }

    };

    if (!check) {
      await setStateAsync({errors: errorMessage, loading: false}, this);
      return false;
    }
    
    if (!validateEmail(this.state.email)) {

      errorMessage.email = 'email invalide';
      await setStateAsync({errors: errorMessage, loading: false}, this);
      return false;

    }

    const newUser = {
      'firstname': this.state.firstname,
      'lastname': this.state.lastname,
      'email': this.state.email,
      'password': this.state.password,
    };

    const httpResponse = await fetchPostURL(`${config.api.base_url}${config.api.end_point.registering}`, newUser);

    if (httpResponse.message === 'email_already_exists') {

      errorMessage.email = 'email déjà utilisé';
      await setStateAsync({errors: errorMessage, loading: false}, this);
      return false;

    }

    if (httpResponse.uid && httpResponse.token) {

      localStorage.setItem('AuthToken', httpResponse.token);
      localStorage.setItem('UserId', httpResponse.uid);
      this.props.navigation('/subscribes');

    }

  }

  render = () => {

    const { classes } = this.props;
    const { errors } = this.state;

    const body = (
      <Container maxWidth="sm" style={{marginTop:45, marginBottom:65}}>
        <ChevronLeft onClick={() => this.props.navigation(`/`)} style={{ color:'white', fontSize:52 }} />
        <TextField
          variant="outlined"
          className={classes.loginTextField}
          fullWidth
          id="firstname"
          label="prénom"
          name="firstname"
          autoComplete="firstname"
          onChange={this.handleChange}
          helperText={errors.firstname}
          error={errors.firstname ? true : false}
        />
        <TextField
          variant="outlined"
          className={classes.loginTextField}
          fullWidth
          id="lastname"
          label="nom"
          name="lastname"
          autoComplete="lastname"
          onChange={this.handleChange}
          helperText={errors.lastname}
          error={errors.lastname ? true : false}
        />
        <TextField
          variant="outlined"
          className={classes.loginTextField}
          fullWidth
          id="email"
          label="email"
          name="email"
          autoComplete="email"
          onChange={this.handleChange}
          helperText={errors.email}
          error={errors.email ? true : false}
        />       
        <TextField
          variant="outlined"
          className={classes.loginTextField}
          fullWidth
          id="password"
          label="mot de passe"
          name="password"
          type="password"
          onChange={this.handleChange}
          helperText={errors.password}
          error={errors.password ? true : false}
        />
        <div style={{textAlign:'center', marginTop:25, color:'#FFF'}}>
          en m'inscrivant, j'accepte les <span style={{textDecoration:'underline'}} onClick={() => this.props.navigation('/conditions')}>conditions générales d'utilisation</span>
        </div>
        <Button
          className={classes.registerButton}
          type="submit"
          fullWidth
          variant="contained"
          onClick={() => this.createUser()}
        >
          {this.state.loading ? <CircularProgress size={30} style={{color:'#fff'}} /> : "créer mon compte" }
        </Button>
      </Container>
    );

    return (<LoginLayout body={body} />);

	}

}

const styles = {
  registerButton: {
    backgroundColor:`${globalStyle.colors.primary}!important`,
    fontSize:16,
    marginTop:35,
    fontWeight:'bold',
    borderRadius:23,
    height:50,
    color:'#ffffff'
  }
}

const App = ( props ) => <CreateUser {...props} navigation={useNavigate()} />;

export default withStyles({...globalStyle.style,...styles})( App );