import React, { Component } from 'react';
import MainLayout from '../layout/main';
import withStyles from '@material-ui/core/styles/withStyles';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';
import globalStyle from '../config/style';
import { useParams } from "react-router-dom";
import { fetchGetURL } from '../utils/fetch';
import { setStateAsync, isset } from '../utils/common';
import UserBadge from '../component/user-badge';
import config from '../config/conf.js';
import appConfig from '../config/application.js';
import { fetchGetURLWithCache } from '../utils/cache';
import { DeviceThermostat, Cloud, Air, Umbrella } from '@mui/icons-material';

class NearbyPartners extends Component {

  constructor (props) {
    super(props);
    this.state = {
      loading: true,
      group: {
        name: '',
        description: ''
      },
      users: [],
      filter: {
        day: null,
        period: null
      },
      weather: {}
    };
  }

  componentDidMount = async () => {
    document.title = "Happy Sports";
    await this.refreshGroupUsers();
    await this.loadWeather();
  }

  loadWeather = async () => {

    const sport = this.props.params.sport;
    let sportData;

    if (appConfig.services)
      sportData = appConfig.services.filter(service => service.sport === sport);

    if (sportData[0] && sportData[0].weather) {
      const weather = await fetchGetURLWithCache(`weather_${sportData[0].weather}`, `${config.api.base_url}${config.api.end_point.getWeather}`, {}, {type: sportData[0].weather});
      this.setState({weather});      
    }

  }

  addDisplayedProperty = (users) => {

    const output = [];

    users.forEach((user) => {
      output.push({...user, displayed: true});
    });

    return output;

  }

  refreshGroupUsers = async () => {

    const sport = this.props.params.sport;

    const users = await fetchGetURL(`${config.api.base_url}${config.api.end_point.nearbyUsers}`, {}, {sport}, true);
    
    this.setState({
      'users': this.addDisplayedProperty(users),
      'loading': false
    });
    
  }

  renderUsers = () => {

    const output = [];

    this.state.users.forEach((user) => {
      if (user.displayed)
        output.push(
          <UserBadge key={user.id} badge={{...user}} showContactButton />
        )
    })

    if (output.length > 0)
      return output;

    return (<div style={{fontFamily:'Poppins-Bold', fontSize:22, lineHeight:'26px', color:'#999', padding:15, textTransform:'uppercase', textAlign:'center'}}>aucune personne à proximité ne correspond à vos critères :(</div>);

  }

  selectFilterDay = async (day) => {

    if (day === this.state.filter.day)
      await setStateAsync( { filter: {day: null, period: null} }, this );
    else
      await setStateAsync( { filter: {day, period: this.state.filter.period} }, this );

    this.applyFilter();

  }

  selectFilterPeriod = async (period) => {

    if (period === this.state.filter.period)
      await setStateAsync( { filter: {day: this.state.filter.day, period: null} }, this );
    else
      await setStateAsync( { filter: {day: this.state.filter.day, period} }, this );
    
    this.applyFilter();

  }

  applyFilter = () => {

    const selectedDay = this.state.filter.day;
    const selectedPeriod = this.state.filter.period;
    const users = this.state.users;
    const usersOutput = [];

    users.forEach((user) => {

      user.period = [];

      Object.keys(user.disponibilities).forEach(k => 
        user.period = user.period.concat(user.disponibilities[k])
      );

      user.period = [...new Set(user.period)];
      usersOutput.push({...user, displayed: false});

    });

    usersOutput.forEach((user, i) => {

      if (selectedDay && selectedPeriod) {
        if (user.disponibilities && user.disponibilities[selectedDay] && user.disponibilities[selectedDay].indexOf(selectedPeriod) !== -1) {
          usersOutput[i] = {...user, displayed: true};
        }
      } else if (selectedDay) {
        if (user.disponibilities && user.disponibilities[selectedDay]) {
          usersOutput[i] = {...user, displayed: true};
        }
      } else if (selectedPeriod) {
        if (user.period && user.period.indexOf(selectedPeriod) !== -1) {
          usersOutput[i] = {...user, displayed: true};
        }
      } else {
        usersOutput[i] = {...user, displayed: true};
      }
      
    });

    this.setState({users: usersOutput});

  }

  styleDayIndicator = (day, selected) => {

    const weather = this.state.weather;
    let score;
    let color;

    if (weather[day] && weather[day].average && isset(weather[day].average.total))
      score = weather[day].average.total;
    else if (selected)
      return { borderTop: `solid 4px #CCC` };
    else
      return { borderTop: `solid 4px #EEE` };

    if (score >= 80)
      color = '#83c683';
    else if (score >= 70)
      color = '#e3e596';
    else if (score >= 50)
      color = '#ffcf77';
    else
      color = '#ff8d8d';

    return { borderTop: `solid 4px ${color}` };

  }

  stylePeriodIndicator = (period, selected) => {

    const weather = this.state.weather;
    const day = this.state.filter.day;
    let score;
    let color;

    if (weather[day] && weather[day].periods && weather[day].periods[period] && isset(weather[day].periods[period].total))
      score = weather[day].periods[period].total;
    else if (selected)
      return { borderTop: `solid 4px #CCC` };
    else
      return { borderTop: `solid 4px #EEE` };

    if (score >= 80)
      color = '#83c683';
    else if (score >= 70)
      color = '#e3e596';
    else if (score >= 50)
      color = '#ffcf77';
    else
      color = '#ff8d8d';

    return { borderTop: `solid 4px ${color}` };

  }

  styleBorderFromScore = (score) => {

    let color;

    if (score >= 80)
      color = '#83c683';
    else if (score >= 70)
      color = '#e3e596';
    else if (score >= 50)
      color = '#ffcf77';
    else
      color = '#ff8d8d';

    return { borderTop: `solid 4px ${color}` };

  }

  renderWeather = () => {

    const weather = this.state.weather;
    const day = this.state.filter.day;
    const period = this.state.filter.period;

    if (!day)
      return null;

    let temperature = null;
    let overcast = null;
    let wind = null;
    let rain = null;

    if (period) {

      if (weather[day] && weather[day].periods && weather[day].periods[period] && weather[day].periods[period].temperature && isset(weather[day].periods[period].temperature.temp))
        temperature = {
          val: weather[day].periods[period].temperature.temp,
          score: weather[day].periods[period].temperature.score
        }

      if (weather[day] && weather[day].periods && weather[day].periods[period] && weather[day].periods[period].clouds && isset(weather[day].periods[period].clouds.overcast))
        overcast = {
          val: weather[day].periods[period].clouds.overcast,
          score: weather[day].periods[period].clouds.score
        }

      if (weather[day] && weather[day].periods && weather[day].periods[period] && weather[day].periods[period].rain && isset(weather[day].periods[period].rain.proba))
        rain = {
          val: weather[day].periods[period].rain.proba,
          score: weather[day].periods[period].rain.score
        }

      if (weather[day] && weather[day].periods && weather[day].periods[period] && weather[day].periods[period].wind && isset(weather[day].periods[period].wind.speed))
        wind = {
          val: weather[day].periods[period].wind.speed,
          score: weather[day].periods[period].wind.score
        }

    } else {

      if (weather[day] && weather[day].average && weather[day].average.temperature && isset(weather[day].average.temperature.temp))
        temperature = {
          val: weather[day].average.temperature.temp,
          score: weather[day].average.temperature.score
        }

      if (weather[day] && weather[day].average && weather[day].average.clouds && isset(weather[day].average.clouds.overcast))
        overcast = {
          val: weather[day].average.clouds.overcast,
          score: weather[day].average.clouds.score
        }

      if (weather[day] && weather[day].average && weather[day].average.rain && isset(weather[day].average.rain.proba))
        rain = {
          val: weather[day].average.rain.proba,
          score: weather[day].average.rain.score
        }

      if (weather[day] && weather[day].average && weather[day].average.wind && isset(weather[day].average.wind.speed))
        wind = {
          val: weather[day].average.wind.speed,
          score: weather[day].average.wind.score
        }

    }

    return (
      <div style={{display:'flex', fontSize:12, flexDirection:'row', justifyContent:'space-around', marginTop:15}}>
        { temperature && <div style={{...this.styleBorderFromScore(temperature.score), ...{width:'18%', fontFamily:'Poppins-Bold', padding:5, paddingTop:7, textAlign:'center', color:'#555', backgroundColor:'#f5f5f5', alignContent:'center', borderRadius:5}}}><div> <DeviceThermostat style={{fontSize:28}} /> </div><div style={{marginTop:-10}}>{temperature.val}°C</div></div> }
        { overcast && <div style={{...this.styleBorderFromScore(overcast.score), ...{width:'18%', fontFamily:'Poppins-Bold', padding:5, paddingTop:7, textAlign:'center', color:'#555', backgroundColor:'#f5f5f5', alignContent:'center', borderRadius:5}}}><div> <Cloud style={{fontSize:28}} /> </div><div style={{marginTop:-10}}>{overcast.val}%</div></div> }
        { rain !== null && <div style={{...this.styleBorderFromScore(rain.score), ...{width:'18%', fontFamily:'Poppins-Bold', padding:5, paddingTop:7, textAlign:'center', color:'#555', backgroundColor:'#f5f5f5', alignContent:'center', borderRadius:5}}}><div> <Umbrella style={{fontSize:28}} /> </div><div style={{marginTop:-10}}>{rain.val}%</div></div> }
        { wind && <div style={{...this.styleBorderFromScore(wind.score), ...{width:'18%', fontFamily:'Poppins-Bold', padding:5, paddingTop:7, textAlign:'center', color:'#555', backgroundColor:'#f5f5f5', alignContent:'center', borderRadius:5}}}><div> <Air style={{fontSize:28}} /> </div><div style={{marginTop:-10}}>{wind.val} km/h</div></div> }
      </div>
    );

  }

  render = () => {

    const days = [
      {ref:'mon', txt:'L'},
      {ref:'tue', txt:'M'},
      {ref:'wed', txt:'M'},
      {ref:'thu', txt:'J'},
      {ref:'fri', txt:'V'},
      {ref:'sat', txt:'S'},
      {ref:'sun', txt:'D'}
    ];

    const periods = [
      {ref:'mor', txt:'matin'},
      {ref:'aft', txt:'après-midi'},
      {ref:'eve', txt:'soirée'},
    ];

    const title = appConfig['sports'][ this.props.params.sport ]['text']['fr'];

    const body = (
      <Container maxWidth="sm" style={{marginTop:15, marginBottom:65}}>

        { this.state.loading ? <div style={{textAlign:"center", marginTop:50}}><CircularProgress style={{color:"red"}} /></div> :

          <div>
            <div style={{fontFamily:'Poppins-Bold', textAlign:'center', textTransform:'uppercase', fontSize:24, letterSpacing:-1}}>{ title }</div>
            
            <div style={{fontFamily:'Poppins-Bold', fontSize:14, textAlign:'center', textTransform:'uppercase', fontWeight:'bold', marginTop:15}}>quand ?</div>

            <div style={{display:'flex', flexDirection:'column', borderRadius:10, padding:10, paddingBottom:20, marginBottom:25, backgroundColor:'#fff', boxShadow:'5px 10px 20px 3px #DDD'}}>
              
              <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', height:44, lineHeight:'40px', marginTop:10, fontWeight:'bold'}}>
                { days.map((day) => this.state.filter.day === day.ref ? <div key={day.ref} onClick={() => this.selectFilterDay(day.ref)} style={{ ...this.styleDayIndicator(day.ref, true), ...{ width:'14%', textAlign:'center', backgroundColor:'#CCC', boxShadow:'0px 0px 7px 0px #999' } } }>{day.txt}</div> :
                  <div key={day.ref} onClick={() => this.selectFilterDay(day.ref)} style={{ ...this.styleDayIndicator(day.ref), ...{width:'14%', textAlign:'center', backgroundColor:'#EEE', color:'#999'}}}>{day.txt}</div>) }
              </div>

              { this.state.filter.day ? <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', fontSize:13, height:34, marginTop:5, lineHeight:'30px', fontWeight:'bold'}}>
                { periods.map((period) => this.state.filter.period === period.ref ? <div key={period.ref} onClick={() => this.selectFilterPeriod(period.ref)} style={{...this.stylePeriodIndicator(period.ref, true), ...{width:'33%', textAlign:'center', backgroundColor:'#CCC', boxShadow:'0px 0px 7px 0px #999'}}}>{period.txt}</div> :
                  <div key={period.ref} onClick={() => this.selectFilterPeriod(period.ref)} style={{...this.stylePeriodIndicator(period.ref), ...{width:'33%', textAlign:'center', backgroundColor:'#EEE', color:'#999'}}}>{period.txt}</div>) }
              </div> : null }
              
              { this.renderWeather() }
            
            </div>

            { this.renderUsers() }

          </div>

        }

      </Container>
    );

    return (<MainLayout back={'/subscribes'} body={body} />);

	}

}

const styles = {
  joinGroupButton: {
    backgroundColor:globalStyle.colors.primary,
    fontSize:16,
    fontWeight:'bold',
    borderRadius:23,
    height:35,
    color:'#ffffff'
  },
  contactUserButton: {
    backgroundColor:globalStyle.colors.primary,
    fontSize:14,
    fontWeight:'bold',
    borderRadius:23,
    height:30,
    color:'#ffffff'
  }
};

const App = ( props ) => {
  return <NearbyPartners {...props} params={useParams()} />;
}

export default withStyles({...globalStyle.style,...styles})( App );