import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Container from '@material-ui/core/Container';
import background from '../asset/image/background-2.jpeg';

class LoginLayout extends Component {

  constructor(props) {
    super(props);
  }

  componentWillMount = () => {
    document.body.style.backgroundSize = 'cover';
    document.body.style.background = `linear-gradient( rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6) ), url(${background}) no-repeat center center fixed`;
  }

  render() {
		return (
      <Container component="main" maxWidth={false} disableGutters={true}>
        { this.props.body }
      </Container>
    );
	}
}

const styles = (theme) => ({});

export default withStyles(styles)(LoginLayout);
