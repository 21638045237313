import React, { Component } from 'react';
import Container from '@material-ui/core/Container';
import Dialog from '@mui/material/Dialog';
import globalStyle from '../config/style';
import withStyles from '@material-ui/core/styles/withStyles';
import { IosShare as IosShareIcon } from '@mui/icons-material';

class InstallTutorial extends Component {

  constructor (props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
    
  }

  render = () => {

    const { classes } = this.props;

    return (
      <Dialog
        className={classes.dialogue}
        open={this.props.show}
        onClose={() => { this.props.setModalStatus(false) }}
      >
        <Container maxWidth="sm" style={{padding:20, paddingTop:25, paddingBottom:40, textAlign:'center'}}>
          <div style={{fontSize:'21px', fontFamily:'Poppins-Bold'}}>Installer Happy Sports</div>
          <div style={{fontSize:'18px', lineHeight:'21px', marginTop:15}}>Installez cette application sur votre écran d'accueil pour y accéder plus rapidement</div>
          <div style={{fontSize:'18px', lineHeight:'21px', marginTop:15}}>1) Ouvrez https://fr.happysports.app avec Safari</div>
          <div style={{fontSize:'18px', lineHeight:'21px', marginTop:15}}>2) Appuyez sur <IosShareIcon /> puis "Sur l'écran d'accueil"</div>
        </Container>
      </Dialog>
    );

  }

}

const styles = {
  commonButton: {
    backgroundColor:`${globalStyle.colors.primary}!important`,
    fontSize:14,
    fontWeight:'bold',
    borderRadius:23,
    height:30,
    color:'#ffffff'
  },
  loginButton: {
    backgroundColor:`${globalStyle.colors.primary}!important`,
    fontSize:16,
    marginTop:10,
    marginBottom:10,
    fontWeight:'bold',
    borderRadius:23,
    height:40,
    color:'#ffffff'
  }
};

const App = ( props ) => <InstallTutorial {...props} />;

export default withStyles({...globalStyle.style,...styles})( App );
