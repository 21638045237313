import React, { Component } from 'react';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Close } from '@mui/icons-material';
import globalStyle from '../config/style';
import withStyles from '@material-ui/core/styles/withStyles';
import { setStateAsync } from '../utils/common';
import { fetchPostURL } from '../utils/fetch';
import { clearCache } from '../utils/cache';
import { useNavigate } from 'react-router';
import config from '../config/conf.js';

class GroupSettings extends Component {

  constructor (props) {
    super(props);
    this.state = {
      group: {},
      buttonLoading: false,
      leaveGroupModal: false,
      errors: {},
      selectedGroup: {
        id: null,
        club: null,
        filter: {
          day: null
        },
        disponibilities: {
          'mon': [],
          'tue': [],
          'wed': [],
          'thu': [],
          'fri': [],
          'sat': [],
          'sun': []
        },
        selectedTags: {}
      }
    };
  }

  componentDidMount = () => {
    this.refreshState();
  }

  refreshState = (prevProps) => {

    if (prevProps && prevProps.show !== this.props.show)
      this.setState({
        selectedGroup:{
          ...this.state.selectedGroup,
          filter: { day: null }
        }
      });

    if (this.state.selectedGroup.id === this.props.group.id)
      return null;

    let disponibilities = this.state.selectedGroup.disponibilities;
    let selectedTags = this.state.selectedGroup.selectedTags;

    if (this.props.group.userDisponibilities)
      disponibilities = this.props.group.userDisponibilities;

    ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'].forEach((day) => {
      if (!disponibilities[day])
        disponibilities[day] = [];
    });

    if (this.props.group.userTags)
      selectedTags = this.props.group.userTags;

    this.setState({
      group: this.props.group,
      selectedGroup:{
        ...this.state.selectedGroup,
        id: this.props.group.id,
        club: this.props.group.club.id,
        filter: { day: null },
        disponibilities,
        selectedTags
      }
    });

  }

  selectFilterDay = async (day) => {

    if (day === this.state.selectedGroup.filter.day)
      await setStateAsync( { selectedGroup: {...this.state.selectedGroup, filter: { ...this.state.selectedGroup.filter, day: null }} }, this );
    else
      await setStateAsync( { selectedGroup: {...this.state.selectedGroup, filter: { ...this.state.selectedGroup.filter, day }} }, this );

  };

  selectFilterPeriod = async (period) => {

    const selectedDay = this.state.selectedGroup.filter.day;
    const disponibilities = this.state.selectedGroup.disponibilities;

    if (disponibilities[selectedDay].indexOf(period) === -1)
      disponibilities[selectedDay].push(period);
    else
      disponibilities[selectedDay].splice( disponibilities[selectedDay].indexOf(period) , 1);

    await setStateAsync({selectedGroup: {...this.state.selectedGroup, disponibilities}}, this);
    
  };

  dayBackgroundColor = (day) => {

    if (this.state.selectedGroup.disponibilities[day].length > 0)
      return '#d1edb7';

    return '#EEE';

  }

  periodIsSelected = (period) => {

    if (!this.state.selectedGroup.filter.day)
      return '#EEE';

    if (this.state.selectedGroup.disponibilities[ this.state.selectedGroup.filter.day ].indexOf(period.ref) !== -1)
      return '#d1edb7';

    return '#EEE';

  }

  shortenerDisponibilities = (disponibilities) => {

    const output = {};

    for (const [key, day] of Object.entries(disponibilities)) {
      if (day.length > 0)
        output[key] = day
    }

    return output;

  }

  saveUpdate = async () => {

    await setStateAsync({buttonLoading: true}, this);

    const clubId = this.state.selectedGroup.club;
    const groupId = this.state.selectedGroup.id;

    // CHECK IF COMPLETED
    // ---

    const { selectedTags, disponibilities } = this.state.selectedGroup;
    const errors = {};

    for (const [key, tag] of Object.entries(selectedTags)) {
      if (tag.length === 0)
        errors[key] = true;
    }

    let dispoError = true;

    for (const [key, dispo] of Object.entries(disponibilities)) {
      if (dispo.length > 0)
        dispoError = false;
    }

    if (dispoError)
      errors['disponibilities'] = true;

    if ( Object.entries(errors).length > 0 ) {
      this.setState({errors, buttonLoading: false})
      return false;
    }

    // OK, CALL THE API
    // ---

    const tags = JSON.stringify(selectedTags);
    const dispos = JSON.stringify(this.shortenerDisponibilities(disponibilities));

    await fetchPostURL(`${config.api.base_url}${config.api.end_point.joinGroup}`, {tags, disponibilities: dispos}, {clubId, groupId});
    await setStateAsync({buttonLoading: false}, this);
    clearCache('userGroups');

    this.props.setModalStatus(false);
    window.location.reload();

  }

  renderPeriodIndicator = (day) => {

    const disponibilities = this.state.selectedGroup.disponibilities;

    return (
      <div style={{position:'absolute', display:'flex', marginLeft:'auto', left:0, right:0, marginRight:'auto', justifyContent:'space-between', flexDirection:'row', bottom:5, width:20}}>
        { disponibilities[day].indexOf('mor') !== -1 ?
          <div style={{width:5, height:5, backgroundColor:'#000'}}></div> :
          <div style={{width:5, height:5, backgroundColor:'#BBB'}}></div>
        }
        { disponibilities[day].indexOf('aft') !== -1 ?
          <div style={{width:5, height:5, backgroundColor:'#000'}}></div> :
          <div style={{width:5, height:5, backgroundColor:'#BBB'}}></div>
        }
        { disponibilities[day].indexOf('eve') !== -1 ?
          <div style={{width:5, height:5, backgroundColor:'#000'}}></div> :
          <div style={{width:5, height:5, backgroundColor:'#BBB'}}></div>
        }
      </div>
    );

  }

  dayHighlighted = (day) => {

    if (this.state.selectedGroup.filter.day === day.ref)
      return '0px 0px 7px 0px #999';

    return 'none';

  }

  invitationBoxTags = (category, multi, tags) => {

    const tagsArray = [];

    for (const [key, tag] of Object.entries(tags)) {
      tagsArray.push(
        <div key={key} onClick={() => this.selectTag(category, key, multi)} style={{...this.getSelectedTags(category, key), color:'#fff', display:'inline-block', fontSize:12, lineHeight:'14px', fontWeight:'bold', borderRadius:10, padding:7, paddingLeft:12, paddingRight:12, margin:3}}>
          {tag}
        </div>
      );
    };

    return (
      <div style={{textAlign:'center', borderRadius:10, marginTop:15, marginBottom:30, padding:10, backgroundColor:'#eee'}}>
        {tagsArray}
        { this.state.errors[category] && <div style={{fontFamily:'Poppins-Bold', letterSpacing:-0.5, paddingTop:10, color:'red', fontSize:13, lineHeight:'14px'}}>merci de sélectionner au moins un tag</div> }
      </div>
    );

  }

  selectTag = (category, tag, multi) => {

    const selectedTags = this.state.selectedGroup.selectedTags;

    if (!selectedTags[category])
      selectedTags[category] = [];

    if ( selectedTags[category].indexOf(tag) !== -1 ) {
      selectedTags[category].splice( selectedTags[category].indexOf(tag) , 1);
    } else if (multi) {
      selectedTags[category].push(tag);
    } else {
      selectedTags[category] = [];
      selectedTags[category].push(tag);
    }

    if (selectedTags[category].length === 0)
      delete selectedTags[category];

    this.setState({selectedGroup:{...this.state.selectedGroup, selectedTags}});

  }

  getSelectedTags = (category, tag) => {

    const selectedTags = this.state.selectedGroup.selectedTags;

    if (!selectedTags[category])
      selectedTags[category] = [];

    if ( selectedTags[category].indexOf(tag) !== -1 )
      return {backgroundColor:'#EE0A4C'};
    else
      return {backgroundColor:'#000'};

  }

  handleLeaveGroup = async () => {

    const clubId = this.state.selectedGroup.club;
    const groupId = this.state.selectedGroup.id;

    await fetchPostURL(`${config.api.base_url}${config.api.end_point.leaveGroup}`, {}, {clubId, groupId});
    clearCache('userGroups');
    
    window.location.reload();

  }

  leaveGroupModal = () => {

    return (
      <Dialog
        open={this.state.leaveGroupModal}
        onClose={() => this.setState({leaveGroupModal: false})}
      >
        <DialogTitle style={{fontFamily:'Poppins-Bold', letterSpacing:-1}}>
          quitter ce groupe ?
        </DialogTitle>
        <DialogActions>
          <Button onClick={() => this.setState({leaveGroupModal: false})}>annuler</Button>
          <Button onClick={this.handleLeaveGroup} autoFocus>
            oui
          </Button>
        </DialogActions>
      </Dialog>
    );

  }

  pressLoginButton = () => {

    if (this.state.group.invitationCode)
      localStorage.setItem('invitationCode', this.state.group.invitationCode);

    this.props.navigation(`/`);

  }

  renderUnconnected = () => {

    const { classes } = this.props;

    return (
      <Container maxWidth="sm" style={{padding:0}}>
        <div style={{padding:25, paddingTop:35, paddingBottom:35, textAlign:'center'}}>
          <div style={{fontFamily:'Poppins-Bold', letterSpacing:-1, marginBottom:20, fontSize:20, lineHeight:'22px'}}>vous devez être connecté pour pouvoir rejoindre un groupe</div>
          <div>
            <Button
              className={classes.loginButton}
              type="submit"
              fullWidth
              variant="contained"
              onClick={this.pressLoginButton}
            >
              me connecter
            </Button>
          </div>
        </div>  
      </Container>
    );

  }

  render = () => {

    const { classes } = this.props;

    const days = [
      {ref:'mon', txt:'L'},
      {ref:'tue', txt:'M'},
      {ref:'wed', txt:'M'},
      {ref:'thu', txt:'J'},
      {ref:'fri', txt:'V'},
      {ref:'sat', txt:'S'},
      {ref:'sun', txt:'D'}
    ];

    const periods = [
      {ref:'mor', txt:'matin'},
      {ref:'aft', txt:'après-midi'},
      {ref:'eve', txt:'soirée'},
    ];

    const selectedGroup = this.props.group;
    let tags = [];

    if (selectedGroup)
      tags = selectedGroup.tags;

    return (
      <Dialog
        className={classes.dialogue}
        open={this.props.show}
        onClose={() => { this.props.setModalStatus(false) }}
      >
        { localStorage.getItem('AuthToken') ? <Container maxWidth="sm" style={{padding:0}}>

          <div style={{position:'absolute', top:10, left:10}} onClick={ () => this.props.setModalStatus(false) }><Close style={{fontSize:32}} /></div>
           
          <div style={{padding:10, paddingTop:35, paddingBottom:35, textAlign:'center'}}>
            
            { (selectedGroup && selectedGroup.disponibilities) && <div style={{marginBottom:20}}>

              <div style={{fontFamily:'Poppins-Bold', textAlign:'center', marginBottom:5, textTransform:'uppercase', color:'#000', fontWeight:'bold', lineHeight:'20px', fontSize:20, letterSpacing:-1}}>
                vos disponibilités
              </div>

              <div style={{display:'flex', flexDirection:'column', borderRadius:10, padding:10, backgroundColor:'#fff'}}>
                
                <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', height:40, lineHeight:'40px', marginBottom:10, fontWeight:'bold'}}>
                  { days.map((day) => <div key={day.ref} onClick={() => this.selectFilterDay(day.ref)} style={{width:'14%', textAlign:'center', position:'relative', backgroundColor:this.dayBackgroundColor(day.ref), boxShadow:this.dayHighlighted(day) }}>{day.txt}{this.renderPeriodIndicator(day.ref)}</div> )}
                </div>
                {this.state.selectedGroup.filter.day && <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', fontSize:13, height:30, lineHeight:'30px', fontWeight:'bold'}}>
                  { periods.map((period) => <div key={period.ref} onClick={() => this.selectFilterPeriod(period.ref)} style={{width:'33%', textAlign:'center', backgroundColor:this.periodIsSelected(period)}}>{period.txt}</div> ) }
                </div>}
                
              </div>

              { this.state.errors.disponibilities && <div style={{fontFamily:'Poppins-Bold', letterSpacing:-0.5, paddingLeft:10, paddingRight:10, color:'red', fontSize:13, lineHeight:'14px'}}>sélectionnez vos disponibilités en choisissant des jours ci dessus</div> }

            </div> }

            <div style={{fontFamily:'Poppins-Bold', textAlign:'center', marginBottom:15, textTransform:'uppercase', color:'#000', fontWeight:'bold', lineHeight:'20px', fontSize:20, letterSpacing:-1}}>vos tags</div>
            
            { tags.map((tag) => <div key={tag.id}><div style={{textAlign:'center', marginBottom:5, textTransform:'uppercase', color:'#666', fontWeight:'bold', lineHeight:'16px', fontSize:16}}>{tag.name}</div>
              {this.invitationBoxTags(tag.id, tag.multi, tag.tags)}</div> )
            }

            <Button
              style={{marginTop:20}}
              className={classes.commonButton}
              type="submit"
              fullWidth
              variant="contained"
              onClick={this.saveUpdate}
              disabled={this.state.buttonLoading}
            >
              { this.state.buttonLoading ?
                <CircularProgress style={{color:"#FFF"}} size={20} /> :
                this.state.group.userDisponibilities || this.state.group.userTags ? "enregistrer" : "valider et rejoindre" }
            </Button>

            { (this.state.group.userDisponibilities || this.state.group.userTags) && <div onClick={() => this.setState({leaveGroupModal: true})} style={{fontFamily:'Poppins-Bold', letterSpacing:-1, textAlign:'center', color:'#999', marginTop:25, fontSize:14}}>quitter ce groupe</div> }

            {this.leaveGroupModal()}

          </div>
        </Container> : this.renderUnconnected() }
      </Dialog>
    );

  }

}

const styles = {
  commonButton: {
    backgroundColor:`${globalStyle.colors.primary}!important`,
    fontSize:14,
    fontWeight:'bold',
    borderRadius:23,
    height:30,
    color:'#ffffff'
  },
  loginButton: {
    backgroundColor:`${globalStyle.colors.primary}!important`,
    fontSize:16,
    marginTop:10,
    marginBottom:10,
    fontWeight:'bold',
    borderRadius:23,
    height:40,
    color:'#ffffff'
  }
};

const App = ( props ) => <GroupSettings {...props} navigation={useNavigate()} />;

export default withStyles({...globalStyle.style,...styles})( App );
