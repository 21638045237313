import React, { Component } from 'react';
import MainLayout from '../layout/main';
import withStyles from '@material-ui/core/styles/withStyles';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';
import globalStyle from '../config/style';
import { useParams } from "react-router-dom";
import { fetchGetURL } from '../utils/fetch';
import { setStateAsync } from '../utils/common';
import UserBadge from '../component/user-badge';
import config from '../config/conf.js';

class Group extends Component {

  constructor (props) {
    super(props);
    this.state = {
      loading: true,
      group: {
        name: '',
        description: ''
      },
      users: [],
      filter: {
        day: null,
        period: null
      }
    };
  }

  componentDidMount = async () => {
    document.title = "Happy Sports";
    await this.refreshGroupUsers();
  }

  addDisplayedProperty = (users) => {

    const output = [];

    users.forEach((user) => {
      output.push({...user, displayed: true});
    });

    return output;

  }

  refreshGroupUsers = async () => {

    const groupId = this.props.params.groupId;
    const clubId = this.props.params.clubId;

    const groupDetails = await fetchGetURL(`${config.api.base_url}${config.api.end_point.groupDetails}`, {}, {clubId, groupId});
    const users = await fetchGetURL(`${config.api.base_url}${config.api.end_point.groupUsers}`, {}, {clubId, groupId}, true);
    
    this.setState({
      'group': {
        'id': groupDetails.id,
        'name': groupDetails.name,
        'clubId': clubId,
        'description': groupDetails.description
      },
      'users': this.addDisplayedProperty(users),
      'loading': false
    });
    
  }

  renderUsers = () => {

    const output = [];
    const uid = localStorage.getItem('UserId');

    this.state.users.forEach((user) => {
      if (user.displayed && user.id != uid)
        output.push(
          <UserBadge key={user.id} badge={{...user, group: this.state.group}} showContactButton />
        )
    })

    if (output.length > 0)
      return output;

    return (<div style={{fontWeight:'bold', fontSize:16, lineHeight:'18px', color:'#777', textAlign:'center'}}>aucune personne de ce groupe ne correspond à vos critères :(</div>);

  }

  selectFilterDay = async (day) => {

    if (day === this.state.filter.day)
      await setStateAsync( { filter: {day: null, period: null} }, this );
    else
      await setStateAsync( { filter: {day, period: this.state.filter.period} }, this );

    this.applyFilter();

  }

  selectFilterPeriod = async (period) => {

    if (period === this.state.filter.period)
      await setStateAsync( { filter: {day: this.state.filter.day, period: null} }, this );
    else
      await setStateAsync( { filter: {day: this.state.filter.day, period} }, this );
    
    this.applyFilter();

  }

  applyFilter = () => {

    const selectedDay = this.state.filter.day;
    const selectedPeriod = this.state.filter.period;
    const users = this.state.users;
    const usersOutput = [];

    users.forEach((user) => {

      user.period = [];

      Object.keys(user.disponibilities).forEach(k => 
        user.period = user.period.concat(user.disponibilities[k])
      );

      user.period = [...new Set(user.period)];
      usersOutput.push({...user, displayed: false});

    });

    usersOutput.forEach((user, i) => {

      if (selectedDay && selectedPeriod) {
        if (user.disponibilities && user.disponibilities[selectedDay] && user.disponibilities[selectedDay].indexOf(selectedPeriod) !== -1) {
          usersOutput[i] = {...user, displayed: true};
        }
      } else if (selectedDay) {
        if (user.disponibilities && user.disponibilities[selectedDay]) {
          usersOutput[i] = {...user, displayed: true};
        }
      } else if (selectedPeriod) {
        if (user.period && user.period.indexOf(selectedPeriod) !== -1) {
          usersOutput[i] = {...user, displayed: true};
        }
      } else {
        usersOutput[i] = {...user, displayed: true};
      }
      
    });

    this.setState({users: usersOutput});

  }

  render = () => {

    const days = [
      {ref:'mon', txt:'L'},
      {ref:'tue', txt:'M'},
      {ref:'wed', txt:'M'},
      {ref:'thu', txt:'J'},
      {ref:'fri', txt:'V'},
      {ref:'sat', txt:'S'},
      {ref:'sun', txt:'D'}
    ];

    const periods = [
      {ref:'mor', txt:'matin'},
      {ref:'aft', txt:'après-midi'},
      {ref:'eve', txt:'soirée'},
    ];

    const body = (
      <Container maxWidth="sm" style={{marginTop:15, marginBottom:65}}>

        { this.state.loading ? <div style={{textAlign:"center", marginTop:50}}><CircularProgress style={{color:"red"}} /></div> :

          <div>
            <div style={{fontFamily:'Poppins-Bold', textAlign:'center', textTransform:'uppercase', fontSize:24, letterSpacing:-1}}>{this.state.group.name}</div>
            <div style={{textAlign:'center', marginBottom:25, textTransform:'uppercase', color:'#666', fontWeight:'bold', lineHeight:'20px', fontSize:18}}>{this.state.group.description}</div>
            
            <div style={{fontFamily:'Poppins-Bold', fontSize:14, textAlign:'center', textTransform:'uppercase', fontWeight:'bold', marginTop:15}}>filtrer</div>

            <div style={{display:'flex', flexDirection:'column', borderRadius:10, padding:10, paddingBottom:20, marginBottom:25, backgroundColor:'#fff', boxShadow:'5px 10px 20px 3px #DDD'}}>
              
              <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', height:40, lineHeight:'40px', marginTop:10, fontWeight:'bold'}}>
                { days.map((day) => this.state.filter.day === day.ref ? <div key={day.ref} onClick={() => this.selectFilterDay(day.ref)} style={{width:'14%', textAlign:'center', backgroundColor:'#EEE', boxShadow:'0px 0px 7px 0px #999'}}>{day.txt}</div> :
                  <div key={day.ref} onClick={() => this.selectFilterDay(day.ref)} style={{width:'14%', textAlign:'center', backgroundColor:'#EEE', color:'#999'}}>{day.txt}</div>) }
              </div>

              { this.state.filter.day ? <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', fontSize:13, height:30, marginTop:5, lineHeight:'30px', fontWeight:'bold'}}>
                { periods.map((period) => this.state.filter.period === period.ref ? <div key={period.ref} onClick={() => this.selectFilterPeriod(period.ref)} style={{width:'33%', textAlign:'center', backgroundColor:'#EEE', boxShadow:'0px 0px 7px 0px #999'}}>{period.txt}</div> :
                  <div key={period.ref} onClick={() => this.selectFilterPeriod(period.ref)} style={{width:'33%', textAlign:'center', backgroundColor:'#EEE', color:'#999'}}>{period.txt}</div>) }
              </div> : null }
              
            </div>

            {this.renderUsers()}

          </div>

        }

      </Container>
    );

    return (<MainLayout back={'/subscribes'} body={body} />);

	}

}

const styles = {
  joinGroupButton: {
    backgroundColor:globalStyle.colors.primary,
    fontSize:16,
    fontWeight:'bold',
    borderRadius:23,
    height:35,
    color:'#ffffff'
  },
  contactUserButton: {
    backgroundColor:globalStyle.colors.primary,
    fontSize:14,
    fontWeight:'bold',
    borderRadius:23,
    height:30,
    color:'#ffffff'
  }
};

const App = ( props ) => {
  return <Group {...props} params={useParams()} />;
}

export default withStyles({...globalStyle.style,...styles})( App );